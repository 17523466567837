import { Box, Skeleton, Stack } from "@mui/material";

export default function TicketsSkeletonLoading({ count = 1 }) {
  const countArr = Array.from({ length: count });

  return countArr.map((_, index) => (
    <Stack key={index} direction={"row"} justifyContent={"space-between"} width={"100%"}>
      <Stack direction={"row"} alignItems={"start"} gap={2} padding={"1.25rem"} width={"100%"}>
        <Box>
          <Skeleton variant="circular" width={"2.5rem"} height={"2.5rem"} animation="wave" />
        </Box>
        <Stack width={"100%"}>
          <Skeleton variant="text" width={"20%"} animation="wave" />
          <Skeleton variant="text" width={"40%"} animation="wave" />
          <Skeleton variant="text" width={"70%"} animation="wave" />
        </Stack>
      </Stack>
      <Stack padding={"1rem"} justifyContent={"space-between"} alignItems={"flex-end"}>
        <Box>
          <Skeleton variant="text" width={"2.25rem"} animation="wave" />
        </Box>
        <Box>
          <Skeleton variant="circular" width={"1rem"} animation="wave" />
        </Box>
      </Stack>
    </Stack>
  ));
}
