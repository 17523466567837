import styleVar from "../assets/scss/_themes-vars.module.scss";
/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */


export default function themePalette(theme) {
  if (theme?.customization?.mode === "dark") {
    return {
      mode: "dark",
      border: `1px solid ${styleVar.borderDark}`,
      common: {
        black: theme.colors?.darkPaper,
      },
      primary: {
        main: theme.colors?.primaryMain,
        200: theme.colors?.primary200,
        800: theme.colors?.primary800,
      },
      secondary: {
        light: theme.colors?.secondaryLight,
        main: theme.colors?.secondaryMain,
        dark: theme.colors?.secondaryDark,
        200: theme.colors?.secondary200,
        800: theme.colors?.secondary800,
      },
      error: {
        light: theme.colors?.errorLight,
        main: theme.colors?.errorMain,
        dark: theme.colors?.errorDark,
      },
      orange: {
        light: theme.colors?.orangeLight,
        main: theme.colors?.orangeMain,
        dark: theme.colors?.orangeDark,
      },
      warning: {
        light: theme.colors?.warningLight,
        main: theme.colors?.warningMain,
        dark: theme.colors?.warningDark,
      },
      success: {
        light: theme.colors?.successLight,
        200: theme.colors?.success200,
        main: theme.colors?.successMain,
        dark: theme.colors?.successDark,
      },
      grey: {
        50: theme.colors?.grey50,
        100: theme.colors?.grey100,
        500: theme.darkTextSecondary,
        600: theme.heading,
        700: theme.darkTextPrimary,
        900: theme.textDark,
      },
      dark: {
        light: theme.colors?.darkTextPrimary,
        main: theme.colors?.darkLevel1,
        dark: "#141c26",
        800: theme.colors?.darkBackground,
        900: theme.colors?.darkPaper,
      },
      text: {
        // primary: theme.darkTextPrimary,
        // secondary: theme.darkTextSecondary,
        // dark: theme.textDark,
        // hint: theme.colors?.grey100,
      },
      background: {
        paper: "#101924",
        default: "#101924",
      },
      backgroundDark: "#101924",
      boxShadow: {
        light: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        dark: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
      },
      cardInner: { main: { padding: "1.5rem" }, lg: { padding: "2.5rem" } },
    };
  } else {
    return {
      mode: "light",
      border: `1px solid ${styleVar.borderLight}`,
      common: {
        black: theme.colors?.darkPaper,
      },
      primary: {
        main: theme.colors?.primaryMain,
        200: theme.colors?.primary200,
        800: theme.colors?.primary800,
      },
      secondary: {
        light: theme.colors?.secondaryLight,
        main: theme.colors?.secondaryMain,
        dark: theme.colors?.secondaryDark,
        200: theme.colors?.secondary200,
        800: theme.colors?.secondary800,
      },
      error: {
        light: theme.colors?.errorLight,
        main: theme.colors?.errorMain,
        dark: theme.colors?.errorDark,
      },
      orange: {
        light: theme.colors?.orangeLight,
        main: theme.colors?.orangeMain,
        dark: theme.colors?.orangeDark,
      },
      warning: {
        light: theme.colors?.warningLight,
        main: theme.colors?.warningMain,
        dark: theme.colors?.warningDark,
      },
      success: {
        light: theme.colors?.successLight,
        200: theme.colors?.success200,
        main: theme.colors?.successMain,
        dark: theme.colors?.successDark,
      },
      grey: {
        50: theme.colors?.grey50,
        100: theme.colors?.grey100,
        500: theme.darkTextSecondary,
        600: theme.heading,
        700: theme.darkTextPrimary,
        900: theme.textDark,
      },
      boxShadow: {
        light: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        dark: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
      },
      cardInner: { main: { padding: "1.5rem" }, lg: { padding: "2.5rem" } },
    };
  }
}
