import { Spinner } from "reactstrap";

export default function LoginRedirectionMsg() {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center w-100 fs-3"
      style={{
        height: "100vh",
      }}
    >
      <p>Redirecting to Login page...</p>
      <Spinner color="primary" size={"xl"} />
    </div>
  );
}
