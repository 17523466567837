import React from "react";
import { Icon } from "../components/Component";

const TalkComponent = () => {
    return (
        <>
            <div className="d-flex align-items-center footer-icons justify-content-center justify-content-lg-end mb-2">
                <span>
                    <Icon name="menu" className="icon" />
                </span>
                <span>
                    <Icon name="video-fill" className="icon" />
                </span>
                <span>
                    <Icon name="mic" className="icon" />
                </span>
                <span className="end-call">
                    <Icon name="call-fill" className="icon red-icon me-0 text-white" />
                </span>
            </div>
        </>
    );
};

export default TalkComponent;
