import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Cookies from "js-cookie";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";

//^ Pages
import Homepage from "../pages/Homepage";

//^ Errors pages
import Error404Modern from "../pages/error/404-modern";

//^ Layouts
import LayoutApp, { loader as indexAppLoader } from "../layout/Index-app";
import TicketLayout, { loader as ticketLayoutLoader } from "../layout/ticket-layout/TicketLayout";

//^ tickets pages
import TicketPage from "../pages/tickets-page/TicketPage";
import CreateTicket from "../components/CreateTicket";
import TicketsPage from "../pages/tickets-page/TicketsPage";

const Router = () => {
  const theme = useTheme();

  // eslint-disable-next-line
  const [_authToken, setAuthToken] = useState(null);
  const [authChecking, setAuthChecking] = useState(false);

  useEffect(() => {
    const at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
    } else {
      setAuthChecking(false);
      setAuthToken(at);
    }
  }, []);

  if (authChecking) return <p>Loading...</p>;

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LayoutApp app={{ icon: "chat", theme: "bg-success-dim", text: "Support" }} />,
      errorElement: <Error404Modern />,
      loader: indexAppLoader,
      id: "index-app-loader",
      children: [
        { index: true, element: <Homepage /> },
        { path: "create-ticket", element: <CreateTicket /> },
      ],
    },
    {
      path: "/ticket",
      element: <TicketLayout app={{ icon: "chat", theme: "bg-success-dim", text: "Support" }} />,
      loader: ticketLayoutLoader,
      children: [
        { index: true, element: <TicketPage /> },
        { path: ":ticketId", element: <TicketsPage /> },
      ],
      errorElement: <Error404Modern />,
    },
  ]);

  return (
    <>
      <Paper elevation={1} sx={{ backgroundColor: theme.palette.mode === "light" ? "#F3F5FA" : "" }}>
        <RouterProvider router={router} />
      </Paper>
    </>
  );
};
export default Router;
