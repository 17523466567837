import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

//^ component
import ErrorAlert from "../../../ui/model/error-model/ErrorModelV2";

import { TCDoughnut } from "../../charts/default/DefaultCharts";
import { getCookie } from "../../../../utils/Utils";

const SupportTicketDoughnut = () => {
  const [ticket, setTicket] = useState("30");
  const [data, setData] = useState({
    labels: ["Closed Ticket", "Open Ticket"],
    dataUnit: "Tickets",
    legend: false,
    datasets: [
      {
        borderColor: "#fff",
        backgroundColor: ["#1ee0ac", "#e85347"],
        data: [0, 0],
      },
    ],
  });

  const totalTickets = data.datasets[0].data[0] + data.datasets[0].data[1];

  const closedPercentage = (data.datasets[0].data[0] / totalTickets) * 100;
  const openPercentage = (data.datasets[0].data[1] / totalTickets) * 100;

  const {
    data: ticketSummaryData,
    isLoading: ticketSummaryIsLoading,
    isRefetching: ticketSummaryIsRefetching,
    isError: ticketSummaryIsError,
    error: ticketSummaryError,
    refetch: ticketSummaryRefetch,
  } = useQuery({
    queryKey: ["get-ticket-summary", ticket],
    queryFn: async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}support/get-ticket-summary`, {
          method: "POST",
          headers: { "Content-Type": "application/json", authToken: getCookie("authToken", undefined) },
          body: JSON.stringify({ duration_type: "d", duration: parseInt(ticket) }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          const error = new Error("An Error occurred while getting the ticket summary.");
          error.info = errorData;
          error.status = response.status;
          throw error;
        }

        return await response.json();
      } catch (error) {
        throw error;
      }
    },
  });

  useEffect(() => {
    if (!ticketSummaryIsLoading || !ticketSummaryIsRefetching) {
      if (ticketSummaryData && !ticketSummaryData?.status) {
        if (ticketSummaryData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
        }

        toast.error(ticketSummaryData?.message);
      } else {
        const SupportTicketDoughnutData = {
          labels: ["Closed Ticket", "Open Ticket"],
          dataUnit: "Tickets",
          legend: false,
          datasets: [
            {
              borderColor: "#fff",
              backgroundColor: ["#1ee0ac", "#e85347"],
              data: [
                ticketSummaryData?.data?.tickets?.closed_tickets,
                ticketSummaryData?.data?.tickets?.active_tickets,
              ],
            },
          ],
        };

        setData(SupportTicketDoughnutData);
      }
    }
  }, [ticketSummaryData, ticketSummaryIsLoading, ticketSummaryIsRefetching]);

  useEffect(() => {
    if (ticketSummaryIsError) {
      console.log(ticketSummaryError?.info);
    }
  }, [ticketSummaryError, ticketSummaryIsError]);

  return (
    <React.Fragment>
      {ticketSummaryIsError && (
        <ErrorAlert
          title={`${ticketSummaryError?.status}` || "500"}
          description={ticketSummaryError?.info?.message || "Something went wrong"}
          isConformed={() => ticketSummaryRefetch()}
        />
      )}
      <div className="card-title-group">
        <div className="card-title card-title-sm">
          <h6 className="title">Support Tickets</h6>
        </div>
        <UncontrolledDropdown>
          <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
            {ticket} Days
          </DropdownToggle>
          <DropdownMenu end className="dropdown-menu-xs">
            <ul className="link-list-opt no-bdr">
              <li className={ticket === "7" ? "active" : ""}>
                <DropdownItem
                  href="#dropdownitem"
                  onClick={(e) => {
                    e.preventDefault();
                    setTicket("7");
                  }}
                >
                  <span>7 Days</span>
                </DropdownItem>
              </li>
              <li className={ticket === "15" ? "active" : ""}>
                <DropdownItem
                  href="#dropdownitem"
                  onClick={(e) => {
                    e.preventDefault();
                    setTicket("15");
                  }}
                >
                  <span>15 Days</span>
                </DropdownItem>
              </li>
              <li className={ticket === "30" ? "active" : ""}>
                <DropdownItem
                  href="#dropdownitem"
                  onClick={(e) => {
                    e.preventDefault();
                    setTicket("30");
                  }}
                >
                  <span>30 Days</span>
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <div className="traffic-channel">
        <div className="traffic-channel-doughnut-ck">
          {ticketSummaryData?.status ? (
            <TCDoughnut state={ticket} data={data} className="analytics-doughnut" />
          ) : (
            <div className="p-4 d-flex justify-content-center align-items-center">
              <p className="fw-bold fs-6 text-danger">{ticketSummaryData?.message}</p>
            </div>
          )}
        </div>
        <div className="traffic-channel-group g-2">
          <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: "#1ee0ac" }} />
              <span>Closed Tickets</span>
            </div>
            <div className="amount">
              {data.datasets[0].data[0]} <small>{isNaN(closedPercentage) ? "0" : closedPercentage.toFixed(2)}%</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: "#e85347" }} />
              <span>Open Tickets</span>
            </div>
            <div className="amount">
              {data.datasets[0].data[1]} <small>{isNaN(openPercentage) ? "0" : openPercentage.toFixed(2)}%</small>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SupportTicketDoughnut;
