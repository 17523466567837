import { useContext, useEffect, useState } from "react";
import { Outlet, json, redirect, useNavigation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//^ utilities
import { getCookie } from "../utils/Utils";

//^ redux action
import { muiAction } from "../store/slices/mui-slice";

//^ components
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppHeader from "./header/AppHeader";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { useTheme } from "./provider/Theme";
import TicketContext from "../components/context/TicketList";

import LoginRedirectionMsg from "../components/ui/redirection-msg/RedirectionMsg";
import { LinearProgress, Stack } from "@mui/material";
import Footer from "./footer/Footer";

const Layout = ({ title, app }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isPageLoading = navigation.state === "loading";

  const showRedirectLogin = useSelector((state) => state.ticket.showRedirectLogin);
  const theme = useTheme();
  const muiTheme = useSelector((state) => state.mui.theme);

  const ticketList = useContext(TicketContext);
  const [newData, setData] = useState(ticketList);
  const [sm, updateSm] = useState(false);
  const [headCreateTicket, setHeadCreateTicket] = useState(true);

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#666" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  return (
    <>
      {showRedirectLogin && <LoginRedirectionMsg />}
      {isPageLoading ? (
        <>
          <Head title={"Loading"} />
          <Stack className="w-100 vh-100">
            <LinearProgress color="primary" />
          </Stack>
        </>
      ) : (
        <TicketContext.Provider
          value={{
            ticketList: newData,
            setData,
            headCreateTicket,
            setHeadCreateTicket,
          }}
        >
          <Head title={!title && "Loading"} />
          <AppRoot className="npc-apps apps-only">
            <AppMain>
              <Sidebar updateSm={updateSm} sm={sm} fixed />
              <AppWrap>
                <AppHeader app={app} fixed isMobile={theme.sidebarMobile} />
                <div className="flex flex-col" style={{ gap: "1rem" }}>
                  <Outlet />
                </div>
                <Footer fixed />
              </AppWrap>
            </AppMain>
          </AppRoot>
        </TicketContext.Provider>
      )}
    </>
  );
};

export async function loader() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}public/check-token`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
  });

  if (!response.ok) {
    throw json({ message: response.statusText }, { status: response.status });
  }

  const resData = await response.json();

  if (!resData?.status) {
    if (resData?.redirect) {
      return redirect(`${process.env.REACT_APP_LOGIN_URL}`);
    }
  }

  const userRoleResponse = await fetch(`${process.env.REACT_APP_API_URL}public/get-role`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
  });

  if (!userRoleResponse.ok) {
    throw json({ message: userRoleResponse?.message }, { status: userRoleResponse.status });
  }

  const userRoleResData = await userRoleResponse.json();

  if (!userRoleResData?.status) {
    if (userRoleResData?.redirect) {
      return redirect(`${process.env.REACT_APP_LOGIN_URL}`);
    }
  }

  const data = {
    checkTokenData: resData,
    userRoleData: userRoleResData,
  };

  return data;
}

export default Layout;
