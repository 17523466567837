import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import filePdf from "../../../images/icons/file-type-pdf.svg";
// import fileimg from "../../../images/icons/file-type-media.svg";
import { Icon } from "../../Component";

import "./FileUpload.css";
import { Box, LinearProgress } from "@mui/material";
import { shortenString } from "../../../utils/Utils";

const FileUpload = ({
  labelName,
  inputFileName,
  files,
  onFileChange,
  onFileRemove,
  showInput = true,
  fileCardStyle,
  limit = 3,
  inputRef
}) => {
  const [selectedFiles, setSelectedFiles] = useState(files || []);
  const [uploadProgress, setUploadProgress] = useState(files ? Array(files.length).fill(100) : []);
  const [buffer, setBuffer] = useState(Array(files ? files.length : 0).fill(0));
  const [fileNames, setFileNames] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [fakeProgress, setFakeProgress] = React.useState(0);
  const [fakeBuffer, setFakeBuffer] = React.useState(10);

  const progressRef = React.useRef(() => { });
  React.useEffect(() => {
    progressRef.current = () => {
      if (fakeProgress > 100) {
        setFakeProgress(0);
        setFakeBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setFakeProgress(fakeProgress + diff);
        setFakeBuffer(fakeProgress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const updatedFileNames = selectedFiles.map((file) => file.name);
    setFileNames(updatedFileNames.join(","));
  }, [selectedFiles]);

  useEffect(() => {
    setBuffer(uploadProgress);
  }, [uploadProgress]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(
      files.map((file) => {
        return (
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      })
    )
    processFiles(files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    processFiles(files);
  };

  const processFiles = (files) => {
    // Filter only image and pdf files
    const filteredFiles = files.filter((file) => file.type.startsWith("image") || file.type === "application/pdf");

    // Check if any file is not accepted
    const invalidFiles = files.filter((file) => !filteredFiles.includes(file));
    if (invalidFiles.length > 0) {
      toast.error("Only image (JPEG, PNG, GIF) and PDF files are allowed.");
      return;
    }
    if (3 <= selectedFiles.length + 1) {
      toast.error("Limit exceeded for selected files (3) images allowed");
    }

    // Limit the number of files to 3
    const newSelectedFiles = filteredFiles.slice(0, limit - selectedFiles.length);

    setSelectedFiles([...selectedFiles, ...newSelectedFiles]);

    // Update file names in the hidden input field
    const updatedProgress = Array(newSelectedFiles.length).fill(0);
    setUploadProgress([...uploadProgress, ...updatedProgress]);

    // Simulate progress with 2.5-second intervals for each file
    newSelectedFiles.forEach((file, index) => {
      if (file.type.startsWith("image")) {
        const interval = 100 / 40; // 100% in 2.5 seconds
        let progress = 0;
        const timer = setInterval(() => {
          progress += interval;
          if (progress >= 100) {
            clearInterval(timer);
          }
          setUploadProgress((prevProgress) => {
            const updatedProgress = [...prevProgress];
            updatedProgress[prevProgress.length - newSelectedFiles.length + index] = progress;
            return updatedProgress;
          });
        }, 62.5); // 40 updates in 2.5 seconds
      } else {
        setTimeout(() => {
          setUploadProgress((prevProgress) => {
            const updatedProgress = [...prevProgress];
            updatedProgress[prevProgress.length - newSelectedFiles.length + index] = 100;
            return updatedProgress;
          });
        }, (index + 1) * 3000);
      }
    });

    // Invoke the onFileChange callback
    if (onFileChange) {
      onFileChange(inputFileName, selectedFiles.concat(newSelectedFiles));
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    const updatedProgress = [...uploadProgress];
    updatedFiles.splice(index, 1);
    updatedProgress.splice(index, 1);
    setSelectedFiles(updatedFiles);
    setUploadProgress(updatedProgress);

    // Update file names in the hidden input field
    const updatedFileNames = updatedFiles.map((file) => file.name);
    setFileNames(updatedFileNames.join(","));

    // Invoke the onFileRemove callback
    if (onFileRemove) {
      onFileRemove(inputFileName, index);
    }
  };

  return (
    <div className={`row d-flex justify-content-center`}>
      {
        selectedFiles.length >= 3 ?
          <>
          </> :
          <>
            <div
              className={`col-md-6 ${isDragging ? "drag-over-label" : ""}`}
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragging(true);
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                setIsDragging(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setIsDragging(false);
              }}
              onDrop={handleDrop}
            >
              <label
                htmlFor={inputFileName}
                className={`${!showInput ? "d-none" : ""} form-label ${isDragging ? "drag-over" : ""}`}
              >
                {labelName}
              </label>
              <label
                className="custom-file-upload d-flex form-label justify-content-center text-center"
                htmlFor={inputFileName}
              >
                <p className="fs-3 m-0">
                  <Icon name="upload-cloud" className="" />
                </p>
                <span className="fw-bolder fs-5">Drag and drop files to upload</span>
                <div className="form-group d-none">
                  <div className="form-control-wrap">
                    <div className="form-file">
                      <input
                        ref={inputRef}
                        type="file"
                        multiple
                        id={inputFileName}
                        accept="image/*, application/pdf"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </>
      }
      <div className="col-md-6 ">
        <div style={{ gap: ".7rem" }} className="d-flex flex-row mt-3" sx={fileCardStyle}>
          {selectedFiles.map((file, index) => (
            <div elevation={3} className="row d-flex flex-wrap" key={index}>
              <div className="d-flex align-items-center" >
                <div className="align-items-center" >
                  <img
                    src={file.type.startsWith("image") ? file.preview : filePdf}
                    alt="file"
                    className="img-fluid rounded-5"
                    style={{ height: "80px", width: "100px" }}
                  />
                </div>
                <div>
                  <p className="filedocumentname ms-2">{shortenString(file.name, 18)}</p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-1">
                <div className="w-100">
                  {uploadProgress[index] !== 100 ?
                    <>
                      <div className="row align-items-center ">
                        <div className="col-md-2 ">
                          <Icon name="loader" className="text-danger text-center reloadicon fs-3" />
                        </div>
                        <Box className="col-md-10">
                          <LinearProgress variant="buffer" value={fakeProgress} valueBuffer={fakeBuffer} />
                        </Box>
                      </div>
                    </>
                    :
                    <div className="row align-items-center justify-content-between">
                      <Icon name="check-circle-fill" className="col-md-2 text-success fs-3" />
                      <Box className="col-md-10">
                        <LinearProgress color="success" variant="buffer" value={uploadProgress[index]} valueBuffer={buffer[index]} />
                      </Box>
                    </div>
                  }
                </div>
                <div className="ms-2 text-danger text-end">
                  {uploadProgress[index] === 100 ? (
                    <>
                      <Icon name="trash" className="cursor-pointers fs-3" onClick={() => handleRemoveFile(index)} />
                    </>
                  ) : (
                    <>
                      <Icon name="cross" className="cursor-pointers fs-3" onClick={() => handleRemoveFile(index)} />
                    </>
                  )}
                </div>
              </div>

            </div>
          ))}
        </div>
        <input type="hidden" name={inputFileName} value={fileNames} />
      </div>
    </div>
  );
};

export default FileUpload;
