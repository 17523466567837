import React, { useEffect, useState } from "react";
import { Map } from "../../charts/default/DefaultCharts";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Typography } from "@mui/material";

const UserMap = ({ onUserMap, userMapData, topFourCountries = [], totalTickets }) => {
  const [mapState, setMapState] = useState("30");

  useEffect(() => {
    onUserMap(mapState);

    // eslint-disable-next-line
  }, [mapState]);

  return (
    <React.Fragment>
      <div className="card-title-group">
        <div className="card-title card-title-sm">
          <h6 className="title">Users by country</h6>
        </div>
        <UncontrolledDropdown>
          <DropdownToggle className="dropdown-indicator btn btn-sm btn-outline-light btn-white">
            {mapState} Days
          </DropdownToggle>
          <DropdownMenu end className="dropdown-menu-xs">
            <ul className="link-list-opt no-bdr">
              <li className={mapState === "7" ? "active" : ""}>
                <DropdownItem
                  tag="a"
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setMapState("7");
                  }}
                >
                  <span>7 Days</span>
                </DropdownItem>
              </li>
              <li className={mapState === "15" ? "active" : ""}>
                <DropdownItem
                  tag="a"
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setMapState("15");
                  }}
                >
                  <span>15 Days</span>
                </DropdownItem>
              </li>
              <li className={mapState === "30" ? "active" : ""}>
                <DropdownItem
                  tag="a"
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setMapState("30");
                  }}
                >
                  <span>30 Days</span>
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <div className="analytics-map">
        <Map supportMapData={userMapData} />
        <table className="analytics-map-data-list">
          <tbody>
            {topFourCountries?.map((topFourCountry, index) => {
              const formattedTopFourCountry = Object.entries(topFourCountry).map(([name, value]) => ({
                name,
                value,
              }))[0];

              const valuePercentage = (formattedTopFourCountry.value / totalTickets) * 100;

              return (
                <tr key={index} className="analytics-map-data">
                  <td className="country">
                    <Typography variant="subtitle1">{formattedTopFourCountry.name}</Typography>
                  </td>
                  <td className="amount">
                    <Typography variant="subtitle1" fontWeight={400}>
                      {formattedTopFourCountry.value}
                    </Typography>
                  </td>
                  <td className="percent">
                    {" "}
                    <Typography variant="subtitle1" fontWeight={400}>{`${valuePercentage.toFixed(2)}%`}</Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default UserMap;
