export const appLinks = [
  // {
  //   showLink: true,
  //   link: `${process.env.REACT_APP_ACCOUNT_URL}apps`,
  //   icon: "list",
  //   text: "Apps",
  // },
  {
    showLink: true,
    link: `${process.env.REACT_APP_ACCOUNT_URL}user-profile-regular`,
    icon: "setting-alt",
    text: "Account Setting",
  },
  {
    showLink: false,
    link: ``,
    darkIcon: "moon",
    lightIcon: "sun",
    textDark: "Dark Mode",
    textLight: "Light Mode",
  },
];
