
import Cookies from "js-cookie";
import axios from "axios";

const authToken = Cookies.get("authToken");
const BASE_URL = process.env.REACT_APP_API_URL || `https://api.silocloud.io/api/v1/`;
const headers = {
    authToken: authToken,
    "Content-Type": "application/json",
};

// Example of a POST request
export const postRequest = async (route) => {
    try {
        let config = {
            method: "post",
            url: `${BASE_URL}${route}`,
            headers: headers,
            //   data: JSON.stringify(data),
            // data: data,
        };

        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error("Error post data:", error);
    }
};

export const postRequestData = async (data, route) => {
    try {
        const response = await fetch(`${BASE_URL}${route}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        });

        const Response = await response.json();

        if (Response.type !== "success") {
            throw new Error("Failed to fetch data From api-services");
        }

        return Response;

    } catch (error) {
        return error.message;
    }
};

// Example of a GET request
export const getRequest = async (route) => {
    try {
        let config = {
            method: "GET",
            url: `${BASE_URL}${route}`,
            headers: headers,
        };

        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error("Error GET data:", error);
    }
};

export const fetchAPI = async (formData, route) => {
    let res = await fetch(`${BASE_URL}${route}`, {
        method: "POST",
        cache: "no-store",
        body: formData,
        headers: headers,
    });
    return await res.json();
};

export const fetchCloudSearchApi = async (route) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
        method: "GET",
        cache: "no-store",
        headers: headers,
    });
    if (!response.ok) {
        const errorData = await response.json();
        const error = new Error("An Error occurred while AppDropdown.");
        error.info = errorData;
        error.code = `${response.status}`;
        throw error;
    }
    return await response.json();
};