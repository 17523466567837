import React, { useCallback, useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import UserMap from "../components/partials/default/user-map/UserMap";
import SupportTicketDoughnut from "../components/partials/default/traffic-dougnut/TrafficDoughnut";
import RecentTicketViewer from "../components/partials/default/page-view/PageView";

import { Button, Card, Grid } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

//^ http request
import { getTicketSummaryHandler } from "../http/post-apis";

//^ hook
import useClientScreenSize from "../hook/user-screen-size";

import { Block, BlockDes, BlockHead, BlockHeadContent } from "../components/Component";
import { uppercaseKeys } from "../utils/Utils";
import ErrorAlert from "../components/ui/model/error-model/ErrorModelV2";

const HomePage = () => {
  const { width } = useClientScreenSize();
  const isLaptopView = width >= 1048;

  const navigate = useNavigate();
  const loaderData = useRouteLoaderData("index-app-loader");
  const userRoleData = loaderData?.userRoleData;

  const [mapDuration, setMapDuration] = useState("30");
  const [mapData, setMapData] = useState({
    GN: 0,
    BH: 0,
    AR: 0,
    CA: 0,
    EG: 0,
    SA: 0,
    AU: 0,
  });
  const [topFourCountries, setTopFourCountries] = useState([]);

  const {
    data: ticketSummaryData,
    isLoading: ticketSummaryIsLoading,
    isRefetching: ticketSummaryIsRefetching,
    isError: ticketSummaryIsError,
    error: ticketSummaryError,
    refetch: ticketSummaryRefetch,
  } = useQuery({
    queryKey: ["get-ticket-summary-for-ticket-maps", mapDuration],
    queryFn: getTicketSummaryHandler,
  });

  useEffect(() => {
    if (!ticketSummaryIsLoading || !ticketSummaryIsRefetching) {
      if (ticketSummaryData && !ticketSummaryData?.status) {
        if (ticketSummaryData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
        } else {
          toast.error(ticketSummaryData?.message);
        }
      } else {
        const supportMapData = ticketSummaryData?.data?.tickets?.country;
        const supportMappedTop4Countries = ticketSummaryData?.data?.tickets?.top_countries;

        const capitalizedSupportMapData = uppercaseKeys(supportMapData);
        setTopFourCountries(supportMappedTop4Countries);
        setMapData(capitalizedSupportMapData);
      }
    }
  }, [ticketSummaryData, ticketSummaryIsLoading, ticketSummaryIsRefetching]);

  useEffect(() => {
    if (ticketSummaryIsError) {
      console.log(ticketSummaryError?.info);
    }
  }, [ticketSummaryError, ticketSummaryIsError]);

  const handleUserMap = useCallback(
    (mapState) => {
      setMapDuration(mapState);
    },

    // eslint-disable-next-line
    [mapDuration]
  );

  const items = [
    {
      id: 1,
      node: (
        <Card elevation={4} sx={{ borderRadius: "0.625rem" }} className="h-100 p-4">
          <SupportTicketDoughnut />
        </Card>
      ),
      xs: isLaptopView ? 6 : 12,
      display: null,
    },
    {
      id: 2,
      node: (
        <>
          {userRoleData?.data?.role?.id === 2 || userRoleData?.data?.role?.id === 6 ? (
            <Card elevation={4} sx={{ borderRadius: "0.625rem" }} className="pb-2 h-100 card-inner">
              <UserMap
                totalTickets={ticketSummaryData?.data?.tickets?.all_tickets || 0}
                onUserMap={handleUserMap}
                userMapData={mapData || []}
                topFourCountries={topFourCountries}
              />
            </Card>
          ) : (
            ""
          )}
        </>
      ),
      display: userRoleData?.data?.role?.id === 2 || userRoleData?.data?.role?.id === 6 ? false : true,
      xs: isLaptopView ? 6 : 12,
    },
    {
      id: 3,
      node: (
        <>
          {userRoleData?.data?.role?.id === 2 || userRoleData?.data?.role?.id === 6 ? (
            <Card elevation={4} sx={{ borderRadius: "0.625rem" }} className=" pb-2 h-100">
              <RecentTicketViewer />
            </Card>
          ) : (
            <Card elevation={4} sx={{ borderRadius: "0.625rem" }} className="pb-2 h-100">
              <RecentTicketViewer />
            </Card>
          )}
        </>
      ),
      display: null,
      xs: userRoleData?.data?.role?.id === 2 || userRoleData?.data?.role?.id === 6 ? 12 : isLaptopView ? 6 : 12,
    },
  ];

  let xs = 6;
  if (items.length === 1) {
    xs = 12;
  }

  return (
    <React.Fragment>
      <Head title="Silo Support" />

      {ticketSummaryIsError && (
        <ErrorAlert
          title={`${ticketSummaryError?.status || "500"}`}
          description={ticketSummaryError?.info?.message || "Something went wrong"}
          isConformed={() => ticketSummaryRefetch()}
        />
      )}

      <Content className="px-0">
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <Button
                  variant="contained"
                  onClick={() => navigate("ticket")}
                  className={`d-flex items-center`}
                  style={{ gap: "0.325rem" }}
                  startIcon={<ConfirmationNumberIcon />}
                >
                  Show Tickets
                </Button>
              </BlockDes>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid sx={{ display: item.display ? "none" : "block" }} item xs={item.xs ? item.xs : xs} key={index}>
                {item.node}
              </Grid>
            ))}
          </Grid>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default HomePage;
