import { getCookie } from "../../utils/Utils";

export async function getCategoriesHandler({ signal }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}support/get-categories`, {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
    },
    signal,
  });

  if (!response.ok) {
    const errorData = await response.json();

    const error = new Error("An Error occurred while fetching categories");
    error.code = response.status;
    error.info = errorData;

    throw error;
  }

  return response.json();
}

export async function getTitleHandler({ categoryId, searchValue }) {
  const formData = new FormData();
  formData.append("category_id", categoryId);
  formData.append("search", searchValue);
  formData.append("limit", "4");
  formData.append("offset", "0");

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}support/get-questions`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken", null),
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error("An Error occurred while fetching titles.");
      error.code = response.status;
      error.info = errorData;
      throw error;
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching titles:", error);
    throw error;
  }
}

export async function getTicketContentHandler({ signal }) {
  try {
    const response = await fetch(`/json-data/ticket-list.json`, {
      signal,
    });

    if (!response.ok) {
      const errorData = await response.json();

      const error = new Error("An error occurred while getting the tickets content.");
      error.info = errorData;
      error.code = response.status;

      throw error;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

//^ ticket http request ==========================================================

export async function getAllTicketHandler({ limit = 10, offset = 0, search, status = "0", type = "status" }) {
  let data = {
    limit,
    offset,
    status,
    type,
  };

  if (search) {
    data = {
      limit,
      offset,
      status,
      type,
      search,
    };
  }

  if (type === "all") {
    data = {
      limit,
      offset,
    };

    if (search) {
      data = {
        limit,
        offset,
        search,
      };
    }
  }

  if (type === "is_stared") {
    data = {
      limit,
      offset,
      is_stared: "1",
    };

    if (search) {
      data = {
        limit,
        offset,
        is_stared: "1",
        search,
      };
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}support/get-all-tickets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authToken: getCookie("authToken"),
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();

      const error = new Error("An error occurred while getting the tickets.");
      error.info = errorData;
      error.code = response.status;

      throw error;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

//^ ticket http request ==========================================================
