import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { DropdownToggle, DropdownMenu, Dropdown, Spinner } from "reactstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import moment from "moment/moment";
import SimpleBar from "simplebar-react";

//^ stylesheets
import styles from "../../../../assets/scss/_themes-vars.module.scss";
import styleVar from "../../../../assets/scss/_themes-vars.module.scss";

//^ http request
import { getAllNotificationHandler, setNotificationCountHandler } from "../../../../http/post-apis";

//^ mui
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, Box, Button, IconButton, MenuItem, MenuList, Stack, Typography } from "@mui/material";

import Icon from "../../../../components/icon/Icon";
import data from "./NotificationData";
import { useThemeUpdate } from "../../../provider/Theme";

import { shortenString } from "../../../../utils/Utils";
import { TooltipComponent } from "../../../../components/Component";
import NotificationSkeletonLoader from "../../../../components/ui/loading/Skeleton/NotificationSkeletonLoader";

function NotificationItem({ title, time, isSender, link, updatedAt, recent, index }) {
  const navigate = useNavigate();

  return (
    <>
      <MenuList component={"li"}>
        <MenuItem
          sx={{ padding: "0.6rem 1rem" }}
          onClick={() => {
            navigate(`${link}`);
          }}
        >
          <Stack justifyContent={"space-between"} alignItems={"flex-start"} direction={"row"} width={"100%"}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Box className="nk-notification-icon">
                <Icon
                  name={isSender ? "curve-down-right" : "curve-down-left"}
                  className={[`icon-circle ${isSender ? "bg-primary-dim" : "bg-success-dim"}`]}
                />
              </Box>
              <Stack className="nk-notification-content">
                <Typography
                  sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  variant="subtitle2"
                  className="nk-notification-text"
                >
                  {title.length > 27 ? (
                    <TooltipComponent
                      type="text"
                      content={shortenString(title, 27)}
                      direction="top"
                      id={`notification-${index}`}
                      text={title}
                    />
                  ) : (
                    title
                  )}
                </Typography>
                <Typography className="nk-notification-time" variant="caption">
                  {time}
                </Typography>
              </Stack>
            </Stack>
            <Stack justifyContent={"flex-start"}>
              <Typography variant="caption" fontWeight={"600"}>
                {moment(updatedAt).format("h:MM A")}
              </Typography>
            </Stack>
          </Stack>
        </MenuItem>
      </MenuList>
    </>
  );
}

const Notification = () => {
  const themeUpdate = useThemeUpdate();

  const [open, setOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState({ notifications: [] });
  const [limit, setLimit] = useState(10);
  const [notificationIds, setNotificationIds] = useState([]);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  // Fetch notifications
  const {
    data: allNotificationData,
    isLoading: allNotificationIsLoading,
    isRefetching: allNotificationIsRefetching,
    isError: allNotificationIsError,
    error: allNotificationError,
    refetch: allNotificationRefetch,
  } = useQuery({
    queryKey: ["get-all-notification", limit],
    queryFn: async () => getAllNotificationHandler({ limit: limit }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (allNotificationIsError) {
      console.log(allNotificationError);
    }
  }, [allNotificationIsError, allNotificationError]);

  //^ Reading all the unread notifications
  const {
    mutate: readUnreadNotificationsMutate,
    reset: readUnreadNotificationsReset,
    error: readUnreadNotificationsError,
    isError: readUnreadNotificationsIsError,
  } = useMutation({
    mutationKey: ["set-notification-count-for-unread-msg"],
    mutationFn: setNotificationCountHandler,
    onSuccess: () => {
      readUnreadNotificationsReset();
      allNotificationRefetch();
    },
  });

  useEffect(() => {
    if (readUnreadNotificationsIsError) {
      console.log(readUnreadNotificationsError?.info);
    }
  }, [readUnreadNotificationsError, readUnreadNotificationsIsError]);

  // Mark all notifications as read mutation
  const { isPending: markAllReadIsPending, mutate: markAllReadMutate } = useMutation({
    mutationKey: ["mark-all-as-read"],
    mutationFn: setNotificationCountHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (!data.status) {
          toast.error(data?.message);
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          }
        } else {
          allNotificationRefetch();
          toast.success(data?.message);
        }
      }
    },
  });

  useEffect(() => {
    if (!allNotificationIsLoading) {
      if (allNotificationData && allNotificationData.status) {
        const unReadNotifications = Array.isArray(allNotificationData.data?.notifications)
          ? allNotificationData.data.notifications.filter((notification) => notification.seen === "0")
          : [];
        setNotificationCount(unReadNotifications.length || 0);

        const newNotifications = allNotificationData.data.notifications.filter((notification) => {
          return !notificationData.notifications.some(
            (existingNotification) => existingNotification.id === notification.id
          );
        });

        setNotificationData((prevNotification) => ({
          notifications: [...prevNotification.notifications, ...newNotifications],
        }));
      } else if (allNotificationData && allNotificationData.redirect) {
        window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
      }
    }

    // eslint-disable-next-line
  }, [allNotificationData, allNotificationIsLoading]);

  useEffect(() => {
    if (!allNotificationIsLoading && allNotificationData) {
      if (notificationData?.status) {
        const notificationsId = allNotificationData?.data?.notifications.map((notification) => notification.id);
        setNotificationIds(notificationsId);
      }
    }

    // eslint-disable-next-line
  }, [allNotificationData, allNotificationIsLoading, setNotificationIds]);

  const loadMoreNotifications = async () => {
    setLimit((prevLimit) => prevLimit + 10);
  };

  function readUnreadNotificationsHandler() {
    readUnreadNotificationsMutate({ notification_id: notificationIds, status: "1" });
  }

  return (
    <>
      {/* Dropdown menu */}
      <Dropdown onBlur={readUnreadNotificationsHandler} isOpen={open} className="user-dropdown" toggle={toggle}>
        <DropdownToggle tag="a" className="dropdown-toggle">
          <IconButton>
            <Badge badgeContent={allNotificationIsLoading ? 0 : notificationCount} color="primary">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
          {/* Header */}
          <div className="dropdown-head">
            <Typography variant="subtitle2">{data.title}</Typography>
            <Button
              size="small"
              onClick={() => {
                if (notificationCount <= 0) {
                  toast.error("There are no unread messages.");
                } else {
                  markAllReadMutate({ notification_id: 0, status: 1 });
                }
              }}
              startIcon={
                markAllReadIsPending ? <Spinner size={"sm"} color="primary" style={{ borderWidth: "1px" }} /> : ""
              }
            >
              Mark All as Read
            </Button>
          </div>

          {/* Body */}
          <div className="dropdown-body">
            <div className="nk-notification">
              {allNotificationIsLoading ? (
                <NotificationSkeletonLoader />
              ) : allNotificationData?.code === 500 ? (
                <>
                  <Typography
                    padding={"1.25rem"}
                    textAlign={"center"}
                    sx={{ color: styleVar.errorMain }}
                    fontWeight={"600"}
                    textTransform={"capitalize"}
                  >
                    {allNotificationData?.message}
                  </Typography>
                </>
              ) : notificationData.notifications.length > 0 ? (
                <>
                  <SimpleBar>
                    {notificationData.notifications.map((notification, index) => {
                      return (
                        <React.Fragment key={index}>
                          <NotificationItem
                            index={index}
                            key={notification.id}
                            title={notification.title}
                            time={moment(notification.created_at).format("DD MMM")}
                            isSender={notification.is_sender}
                            link={notification.link}
                            updatedAt={notification.updated_at}
                            recent={notification.recent}
                          />
                        </React.Fragment>
                      );
                    })}
                    {/* View More button */}
                    {!allNotificationIsRefetching && (
                      <div className="dropdown-foot center">
                        <Button
                          endIcon={
                            allNotificationIsRefetching ? (
                              <Spinner size="sm" style={{ borderWidth: "1px", color: "inherit" }} />
                            ) : (
                              ""
                            )
                          }
                          onClick={loadMoreNotifications}
                          disabled={allNotificationIsRefetching}
                        >
                          {"View More"}
                        </Button>
                      </div>
                    )}
                  </SimpleBar>
                </>
              ) : (
                // No notifications found
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <Typography variant="subtitle1" sx={{ padding: "1.25rem", color: styles.primaryMain }}>
                    No Notification Found
                  </Typography>
                </Stack>
              )}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default Notification;
