import { useNavigate, useLocation } from "react-router";
import { Stack } from "@mui/material";
import { useEffect } from "react";

//^ component
import EmptyTicket from "../../components/svg/empty-ticket";

export default function TicketPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const isIndex = location.search === "?index";

  useEffect(() => {
    navigate("");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isIndex ? (
        <Stack alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100vh"}>
          <EmptyTicket />
        </Stack>
      ) : null}
    </>
  );
}
