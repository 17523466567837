import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams, useNavigation, json, redirect } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import moment from "moment/moment";
import debounce from "lodash/debounce";

//^ redux actions
import { ticketAction } from "../../store/slices/ticket-slice";

//^ layout
import { useTheme } from "../provider/Theme";

//^ utils
import { getCookie, getInitials, shortenString } from "../../utils/Utils";

//^ http request
import { getAllTicketHandler } from "../../http/get-apis";
import { changeStatusTicketHandler, getAllAssignUsersHandler } from "../../http/post-apis";

//^ mui
import {
  Stack,
  Button,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  AvatarGroup,
  Avatar,
  Skeleton,
} from "@mui/material";
import { StarBorderRounded, StarRounded } from "@mui/icons-material";
import { red } from "@mui/material/colors";

//^ redux actions
import { muiAction } from "../../store/slices/mui-slice";

//^ components
import { Icon, TooltipComponent } from "../../components/Component";
import { tabItems } from "./ticket-tab-items";
import ContentAlt from "../content/ContentAlt";
import Sidebar from "../sidebar/Sidebar";
import AppHeader from "../header/AppHeader";
import AppRoot from "../global/AppRoot";
import AppMain from "../global/AppMain";
import AppWrap from "../global/AppWrap";
import TicketContext from "../../components/context/TicketList";
import ErrorAlert from "../../components/ui/model/error-model/ErrorModelV2";
import LoginRedirectionMsg from "../../components/ui/redirection-msg/RedirectionMsg";

//^ skeleton loading
import TicketsSkeletonLoading from "../../components/ui/loading/Skeleton/TicketsSkeletonLoading";
import PageLoader from "../../components/ui/loading/page-loader/PageLoader";
import TicketNotFoundTwo from "../../components/svg/ticket-not-found-two";
import Footer from "../footer/Footer";

function TicketItem(props) {
  const [getAssignedUsers, setGetAssignedUsers] = useState([]);

  const {
    data: getAssignUsersData,
    isLoading: getAssignUsersIsLoading,
    isRefetching: getAssignUsersIsRefetching,
    isError: getAssignUsersIsError,
    error: getAssignUsersError,
    refetch: getAssignUsersRefetch,
  } = useQuery({
    queryKey: ["get-all-ticket-assign-users", props.item.ticket_unique_id],
    queryFn: async () =>
      getAllAssignUsersHandler({
        assignUser: true,
        ticket_id: props.item.ticket_unique_id,
      }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getAssignUsersIsLoading || !getAssignUsersIsRefetching) {
      if (getAssignUsersData?.status) {
        const filteredTechUsers = getAssignUsersData?.data?.tech_users.filter((user) => user.isAssigned);
        setGetAssignedUsers(filteredTechUsers);
      } else {
        if (getAssignUsersData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
        } else {
          toast.error(getAssignUsersData?.data?.message);
        }
      }
    }
  }, [getAssignUsersData, getAssignUsersIsLoading, getAssignUsersIsRefetching]);

  useEffect(() => {
    if (getAssignUsersIsError) {
      console.log(getAssignUsersError?.info);
    }
  }, [getAssignUsersIsError, getAssignUsersError]);

  return (
    <>
      {getAssignUsersIsError && (
        <ErrorAlert
          title={`${getAssignUsersError?.status || 500}`}
          description={getAssignUsersError?.info?.message || "Something went wrong"}
          isConformed={() => getAssignUsersRefetch()}
        />
      )}

      <div
        className={`w-100 nk-msg-item pb-2 ${props.ticketParamId === props.item.ticket_unique_id ? "current" : ""}`}
        onClick={(ev) => props.onMessageClick(ev, props.item.ticket_unique_id)}
      >
        <Stack width={"100%"}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"} width={"100%"}>
            <Stack gap={"0.325rem"} width={"100%"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                alignItems={"flex-start"}
                className="nk-msg-from w-100"
              >
                <Stack direction={"row"} gap={"0.425rem"} className="nk-msg-sender">
                  <Avatar sx={{ width: 40, height: 40, bgcolor: red[500] }} alt={props.item.user.username}>
                    <Typography fontWeight={500} variant="subtitle1" color={"white"}>
                      {getInitials(props.item.user.username)}
                    </Typography>
                  </Avatar>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography className="name">{props.item.user.username}</Typography>
                    {props.item.status === "1" && <div className={`lable-tag dot bg-danger`} />}
                  </Stack>
                </Stack>
                <div className="nk-msg-meta">
                  <Stack gap={"0.625rem"} justifyContent={"center"} alignItems={"center"}>
                    <Typography variant="subtitle2" className="date">
                      {moment(props.item.created_at).format("DD MMM")}
                    </Typography>
                    <Box>
                      <IconButton
                        onClick={(ev) =>
                          props.stareTicketHandler(ev, props.item.ticket_unique_id, props.item.is_stared)
                        }
                      >
                        {props.ticketStatusIsPending && props.ticketId === props.item.id ? (
                          <Spinner size={"sm"} />
                        ) : props.item.is_stared === "1" ? (
                          <StarRounded sx={{ fontSize: "1.25rem" }} fill="#FFD700" />
                        ) : (
                          <StarBorderRounded sx={{ fontSize: "1.25rem" }} />
                        )}
                      </IconButton>
                    </Box>
                  </Stack>
                </div>
              </Stack>
              <div style={{ paddingLeft: 40 }} className="nk-msg-context w-100">
                <div className="nk-msg-text w-100">
                  <h6 className="title">{props.item.title}</h6>
                  <p>{shortenString(props.item.category.description, 287)}</p>
                </div>
              </div>
            </Stack>
          </Stack>
          <Stack>
            {getAssignUsersIsLoading || getAssignUsersIsRefetching ? (
              <Box>
                <AvatarGroup>
                  <Skeleton animation="wave" sx={{ width: 30, height: 30 }} variant="circular" />
                  <Skeleton animation="wave" sx={{ width: 30, height: 30 }} variant="circular" />
                  <Skeleton animation="wave" sx={{ width: 30, height: 30 }} variant="circular" />
                </AvatarGroup>
              </Box>
            ) : (
              <AvatarGroup
                max={4}
                sx={{
                  "& .MuiAvatar-root": { width: 30, height: 30, bgcolor: red[500] },
                }}
                renderSurplus={(surplus) => (
                  <Typography fontWeight={500} variant="subtitle2" color={"white"}>
                    {`+${surplus}`}
                  </Typography>
                )}
              >
                {getAssignedUsers.map((user, index) => {
                  let count = index;
                  count++;

                  return (
                    <TooltipComponent
                      key={index}
                      type="avatar-group"
                      content={
                        <>
                          <Avatar
                            id={`avatar-${count}`}
                            key={index}
                            sx={{ width: 30, height: 30 }}
                            alt={user?.username}
                          >
                            <Typography fontWeight={500} variant="subtitle2" color={"white"}>
                              {getInitials(user?.username)}
                            </Typography>
                          </Avatar>
                        </>
                      }
                      direction="top"
                      id={`avatar-${count}`}
                      text={user?.username}
                    />
                  );
                })}
              </AvatarGroup>
            )}
          </Stack>
        </Stack>
      </div>
    </>
  );
}

const TicketLayout = ({ app }) => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const theme = useTheme();

  const isPageLoading = navigation.state === "loading";

  const muiTheme = useSelector((state) => state.mui.theme);
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const params = useParams();
  const ticketParamId = params.ticketId;

  const [ticketId, setTicketId] = useState(null);
  const [headCreateTicket, setHeadCreateTicket] = useState(true);
  const [selectedTab, setSelectedTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [search, setOnSearch] = useState(false);
  const [type, setType] = useState("status");
  const [status, setStatus] = useState("0");

  const [limit, setLimit] = useState(10);
  // eslint-disable-next-line
  const [offset, _setOffSet] = useState(0);

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#666" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  //^ get all ticket data query
  const {
    data: allTicketData,
    isLoading: allTicketIsLoading,
    isRefetching: allTicketIsRefetching,
    isError: allTicketIsError,
    error: allTicketError,
    refetch: allTicketRefetch,
  } = useQuery({
    queryKey: ["get-all-tickets", searchText, selectedTab, type, status, limit, offset],
    queryFn: () =>
      getAllTicketHandler({ limit: limit, offset: offset, status: status, type: type, search: searchText }),
  });

  useEffect(() => {
    if (!allTicketIsLoading) {
      if (allTicketData.status) {
        if (!ticketParamId) {
          navigate(`/ticket/${allTicketData?.data.tickets[0].ticket_unique_id || 2}`);
        }
      } else {
        if (allTicketData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
        }
      }
    }
  }, [allTicketData, allTicketIsLoading, ticketParamId, navigate]);

  //^ change ticket status mutation query
  const {
    isPending: ticketStatusIsPending,
    isError: ticketStatusIsError,
    error: ticketStatusError,
    mutate: ticketStatusMutate,
    reset: ticketStatusReset,
  } = useMutation({
    mutationKey: ["change-ticket-status"],
    mutationFn: changeStatusTicketHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.status) {
          toast.success(data.message);
          allTicketRefetch();
        } else {
          toast.error(data.message);
        }
      }

      ticketStatusReset();
    },
  });

  useEffect(() => {
    if (ticketStatusIsError) {
      console.log(ticketStatusError?.info);
    }
  }, [ticketStatusError, ticketStatusIsError]);

  useEffect(() => {
    if (allTicketIsError) {
      console.log(allTicketError?.info);
    }
  }, [allTicketError, allTicketIsError]);

  const debouncedOnSearchInputChange = debounce((ev) => {
    setSearchText(ev.target.value);
  }, 500);

  const onSearchInputChange = (ev) => {
    debouncedOnSearchInputChange(ev);
  };

  const onMessageClick = (_ev, id) => {
    if (ticketParamId !== `${id}`) {
      navigate(`/ticket/${id}`);
    }

    if (window.innerWidth <= 990) {
      dispatch(ticketAction.setTicketMobileViewHandler(true));
    }
  };

  const onSearchBack = () => {
    setOnSearch(false);
    setSearchText("");
  };

  const stareTicketHandler = (ev, ticketId, isStared) => {
    ev.preventDefault();

    if (isStared === "1") {
      ticketStatusMutate({ status: 0, ticket_id: ticketId, type: "is_stared" });
    } else if (isStared === "0") {
      ticketStatusMutate({ status: 1, ticket_id: ticketId, type: "is_stared" });
    }

    setTicketId(ticketId);
  };

  function changeTicketStatusHandler(status, type, id) {
    setStatus(status);
    setType(type);
    setSelectedTab(id);
  }

  function loadMoreTicketsHandler() {
    setLimit((prevLimit) => prevLimit + 10);
  }

  return (
    <>
      {ticketStatusIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to change the status of the ticket.`}
          isConformed={() => ticketStatusReset()}
        />
      )}
      {allTicketIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to create a new ticket.`}
          isConformed={() => allTicketRefetch()}
        />
      )}
      {isPageLoading ? (
        <>
          <PageLoader />
        </>
      ) : (
        ""
      )}
      {!allTicketData?.status && allTicketData?.redirect ? (
        <LoginRedirectionMsg />
      ) : (
        <TicketContext.Provider
          value={{
            ticketList:
              !allTicketIsLoading || allTicketIsRefetching || !allTicketIsRefetching
                ? allTicketData?.status
                  ? allTicketData?.data?.tickets
                  : []
                : [],
            headCreateTicket,
            setHeadCreateTicket,
          }}
        >
          <AppRoot className="npc-apps apps-only">
            <AppMain>
              {theme.sidebarMobile && <Sidebar fixed />}
              <AppWrap>
                <AppHeader app={app} fixed isMobile={theme.sidebarMobile} />
                <ContentAlt>
                  <div className="nk-msg">
                    <div className="nk-msg-aside hide-aside">
                      <div className="nk-msg-nav">
                        <ul className="nk-msg-menu">
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            width={"100%"}
                          >
                            {tabItems.map((tabItem, index) => {
                              return (
                                <li
                                  key={index}
                                  className={`nk-msg-menu-item ${
                                    selectedTab === tabItem.id.toString() ? "active" : ""
                                  }`}
                                  onClick={() =>
                                    changeTicketStatusHandler(tabItem.status, tabItem.type, tabItem.id.toString())
                                  }
                                >
                                  <a href={`#${tabItem.value}`} onClick={(ev) => ev.preventDefault()}>
                                    {tabItem.title}
                                  </a>
                                </li>
                              );
                            })}

                            <li onClick={() => setOnSearch(true)}>
                              <a
                                href="#search"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <IconButton>
                                  <Icon name="search" className={"fs-5"} />
                                </IconButton>
                              </a>
                            </li>
                          </Stack>
                        </ul>
                        <div className={`search-wrap ${search && "active"}`}>
                          <div className="search-content align-items-center">
                            <Stack direction={"row"} alignItems={"center"}>
                              <a
                                href="#search"
                                className="search-back toggle-search"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  onSearchBack();
                                }}
                              >
                                <IconButton>
                                  <Icon className={"fs-5"} name="arrow-left" />
                                </IconButton>
                              </a>
                              <input
                                type="text"
                                className="border-transparent form-focus-none form-control"
                                placeholder="Search by ticket title..."
                                onChange={(e) => onSearchInputChange(e)}
                              />
                              <IconButton className="position-absolute search-submit">
                                <Icon name="search" className={"fs-5"} />
                              </IconButton>
                            </Stack>
                          </div>
                        </div>
                      </div>

                      <SimpleBar className="nk-msg-list position-relative">
                        <>
                          {allTicketIsLoading ? (
                            <>
                              <TicketsSkeletonLoading count={10} />
                            </>
                          ) : allTicketData?.status ? (
                            <Stack alignItems={"center"} width={"100%"}>
                              {allTicketData?.data?.tickets.length > 0 ? (
                                allTicketData?.data?.tickets.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <TicketItem
                                        key={index}
                                        ticketParamId={ticketParamId}
                                        ticketId={ticketId}
                                        ticketStatusIsPending={ticketStatusIsPending}
                                        onMessageClick={onMessageClick}
                                        stareTicketHandler={stareTicketHandler}
                                        item={item}
                                      />
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <Stack
                                  className="nk-msg-item position-absolute"
                                  justifyContent={"flex-end"}
                                  alignItems={"flex-end"}
                                  sx={{ bottom: "-3.9rem" }}
                                >
                                  <TicketNotFoundTwo />
                                </Stack>
                              )}
                              {allTicketData && allTicketData?.data?.tickets?.length >= 10 ? (
                                <Button type="button" className="my-3" onClick={loadMoreTicketsHandler} variant="contained">
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box>Load More</Box>
                                    {allTicketIsRefetching ? (
                                      <Stack alignItems={"center"}>
                                        <CircularProgress className="text-white" size={15} />
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                  </Stack>
                                </Button>
                              ) : (
                                ""
                              )}
                            </Stack>
                          ) : (
                            <Stack
                              className="nk-msg-item position-absolute"
                              justifyContent={"flex-end"}
                              alignItems={"flex-end"}
                              sx={{ bottom: "-3.9rem" }}
                            >
                              <TicketNotFoundTwo />
                            </Stack>
                          )}
                        </>
                      </SimpleBar>
                    </div>
                    {/*nk-aside*/}
                    <Outlet />
                  </div>
                  <Footer/>
                </ContentAlt>
              </AppWrap>
            </AppMain>
          </AppRoot>
        </TicketContext.Provider>
      )}
    </>
  );
};

export async function loader() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}public/check-token`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
  });

  if (!response.ok) {
    throw json({ message: response.statusText }, { status: response.status });
  }

  const resData = await response.json();

  if (!resData?.status) {
    if (resData?.redirect) {
      return redirect(`${process.env.REACT_APP_LOGIN_URL}`);
    }
  }

  return resData;
}

export default TicketLayout;
