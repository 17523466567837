import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

//^ http request
import { getTicketHandler, getUserRoleHandler } from "../../http/post-apis";

//^ components
import ErrorAlert from "../../components/ui/model/error-model/ErrorModelV2";
import TicketDetail from "./ticket-detail/TicketDetail";
import Head from "../../layout/head/Head";

//^ skeleton loader
import TicketPageSkeleton from "../../components/ui/loading/Skeleton/ticket-page-skeleton";

export default function TicketsPage() {
  // eslint-disable-next-line
  const params = useParams();
  const ticketId = params.ticketId;

  const [ticketData, setTicketData] = useState(undefined);
  const [userRole, setUserRole] = useState("0");

  //^ getting current user role query
  const {
    data: getUserRoleData,
    isLoading: getUserRoleIsLoading,
    isRefetching: getUserRoleIsRefetching,
    isError: getUserRoleIsError,
    error: getUserRoleError,
    refetch: getUserRoleRefetch,
  } = useQuery({
    queryKey: ["get-user-role"],
    queryFn: async () => getUserRoleHandler(),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getUserRoleIsLoading || !getUserRoleIsRefetching) {
      if (!getUserRoleData?.status) {
        if (getUserRoleData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
        }
      } else {
        setUserRole(getUserRoleData?.data?.role?.id);
      }
    }
    // eslint-disable-next-line
  }, [getUserRoleData, getUserRoleIsLoading, getUserRoleIsRefetching]);

  useEffect(() => {
    if (getUserRoleIsError) {
      console.log(getUserRoleError?.info);
    }
  }, [getUserRoleError, getUserRoleIsError]);

  //^ getting ticket data query
  const {
    data: getTicketData,
    isLoading: getTicketIsLoading,
    isRefetching: getTicketIsRefetching,
    isError: getTicketIsError,
    error: getTicketError,
    refetch: getTicketRefetch,
  } = useQuery({
    queryKey: ["get-current-ticket", ticketId],
    queryFn: () => getTicketHandler({ ticketId }),
    gcTime: 0,
    staleTime: Infinity,
    enabled: typeof ticketId === "string",
  });

  useEffect(() => {
    if (!getTicketIsLoading || !getTicketIsRefetching) {
      if (getTicketData?.status) {
        setTicketData(getTicketData.data);
      }
    } else {
      if (getTicketData?.redirect) {
        window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
      }
    }
    // eslint-disable-next-line
  }, [getTicketData, getTicketIsLoading, getTicketIsRefetching, ticketData]);

  useEffect(() => {
    if (getTicketIsError) {
      console.log(getTicketError);
    }
  }, [getTicketIsError, getTicketError]);

  useEffect(() => {
    if (!getTicketIsLoading || !getTicketIsRefetching) {
      if (!getTicketData?.status) {
        if (getTicketData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
        }
      }
    }
  }, [getTicketData, getTicketIsLoading, getTicketIsRefetching]);

  return (
    <>
      <Head title={"Tickets"} />
      {getUserRoleIsError && (
        <ErrorAlert
          title={`${getUserRoleError?.status || 500}`}
          description={getUserRoleError?.info?.message || "Something went wrong"}
          isConformed={() => getUserRoleRefetch()}
        />
      )}
      {getTicketIsError && (
        <ErrorAlert
          title={`${getTicketError?.status || 500}`}
          description={getTicketError?.info?.message || "Something went wrong"}
          isConformed={() => getTicketRefetch()}
        />
      )}
      {getTicketIsLoading || getTicketIsRefetching || getUserRoleIsLoading || getUserRoleIsRefetching ? (
        <>
          <TicketPageSkeleton />
        </>
      ) : (
        <>
          {getTicketData && !getTicketData?.status ? (
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <p className="fs-4 fw-bold text-danger">{getTicketData?.message}</p>
            </div>
          ) : (
            <TicketDetail userRole={userRole} ticketData={ticketData} ticketRefetch={getTicketRefetch} />
          )}
        </>
      )}
    </>
  );
}
