import parse from "html-react-parser";
import moment from "moment";
import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

//^ stylesheet
import styleVar from "../../../assets/scss/_themes-vars.module.scss";

//^ http request
import { downloadTicketAttachment } from "../../../http/post-apis";

//^ utils
import { downloadBlob, getInitials } from "../../../utils/Utils";

//^ mui
import { Avatar, Box, Stack, Typography, Card, IconButton, CircularProgress } from "@mui/material";
import { AttachFile, MailLock as MailLockIcon, FileDownloadOutlined } from "@mui/icons-material";
import { red } from "@mui/material/colors";

//^ components
import { getFileIcon } from "../../tickets-page/ticket-detail/TicketDetail";
import { Icon } from "../../../components/Component";

export const ReplyItem = ({ item, userRole }) => {
  const [fileAttIdx, setFileAttIdx] = React.useState(undefined);
  const [filename, setFilename] = React.useState("");

  const {
    isPending: downTicketAttachmentIsPending,
    isError: downTicketAttachmentIsError,
    error: downTicketAttachmentError,
    mutate: downTicketAttachmentMutate,
    reset: downTicketAttachmentReset,
  } = useMutation({
    mutationKey: ["download-ticket-attachment"],
    mutationFn: downloadTicketAttachment,
    onSuccess: (blob) => {
      downloadBlob(blob, filename);
      downTicketAttachmentReset();
    },
  });

  const reply = JSON.parse(item.reply);
  const fileUploads = item?.file_upload ? JSON.parse(item?.file_upload) : [];
  const fileDetails = fileUploads.map((filePath) => {
    const fileName = filePath.split("/").pop();
    const [filename, extension] = fileName.split(".");
    const icon = getFileIcon(extension.toLowerCase());
    return { filename, extension, icon, path: filePath };
  });

  const downloadAttachments = (fileString, index, filename) => {
    downTicketAttachmentMutate({ file_path: fileString, ticketId: item.id, type: "reply" });
    setFileAttIdx(index);
    setFilename(filename);
  };

  useEffect(() => {
    if (downTicketAttachmentIsError) {
      console.log(downTicketAttachmentError?.info);
      toast.error(downTicketAttachmentError?.info?.message);
    }
  }, [downTicketAttachmentIsError, downTicketAttachmentError]);

  return (
    <>
      {userRole === 2 || userRole === 6 ? (
        <div className="nk-reply-item" key={item.id}>
          <div className="nk-reply-header">
            <div className="user-card">
              <Avatar sx={{ width: 40, height: 40, bgcolor: red[500] }} alt={`${item.id}`}>
                <Typography fontWeight={500} variant="subtitle1" color={"white"}>
                  {getInitials(item.user.username)}
                </Typography>
              </Avatar>
              <Stack direction={"row"} gap={"0.625rem"} className="user-name" alignItems={"center"}>
                <Typography
                  textTransform={"capitalize"}
                  fontWeight={"600"}
                  variant="h6"
                >{`${item.user.username}`}</Typography>
                {item.chat_type === "2" && <MailLockIcon sx={{ fontSize: "1.25rem" }} />}
              </Stack>
            </div>
            <div className="date-time">{moment(item.created_at).format("h:mm A")}</div>
          </div>
          <div className="nk-reply-body">
            <Card sx={{ boxShadow: "none" }} variant="outlined" className={`nk-reply-entry entry note rounded-3 p-2`}>
              <Typography>{reply}</Typography>
            </Card>
            {fileUploads.length > 0 ? (
              <div className="attach-files">
                <div className="d-flex align-items-center" style={{ gap: "0.625rem" }}>
                  <ul className="attach-list">
                    {fileDetails.map((att, index) => (
                      <li className="attach-item" key={index}>
                        <Box className="download" width={"100%"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            gap={"1.25rem"}
                          >
                            <Stack direction={"row"} gap={"0.425rem"} alignItems={"center"}>
                              <AttachFile color="primary" sx={{ fontSize: "1.4rem" }} />
                              <span>{att.filename}</span>
                            </Stack>
                            <IconButton
                              sx={{
                                "&:hover": {
                                  color: styleVar.errorMain,
                                },
                              }}
                              onClick={() => downloadAttachments(att.path, index, att.filename || "")}
                              disabled={fileAttIdx === index && downTicketAttachmentIsPending}
                            >
                              {fileAttIdx === index && downTicketAttachmentIsPending ? (
                                <CircularProgress size={24} color="primary" />
                              ) : (
                                <FileDownloadOutlined />
                              )}
                            </IconButton>
                          </Stack>
                        </Box>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="attach-foot">
                  <span className="attach-info">{fileUploads.length} files attached</span>
                  <a
                    className="attach-download link d-none"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      downloadAttachments("");
                    }}
                  >
                    <Icon name="download" />
                    <span>Download All</span>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : item.chat_type === "1" ? (
        <div className="nk-reply-item" key={item.id}>
          <div className="nk-reply-header">
            <div className="user-card">
              <Avatar sx={{ width: 40, height: 40, bgcolor: red[500] }} alt={`${item.id}`}>
                <Typography fontWeight={500} variant="subtitle1" color={"white"}>
                  {getInitials(item.user.username)}
                </Typography>
              </Avatar>
              <div className="user-name">{`${item.user.username}`}</div>
            </div>
            <div className="date-time">{moment(item.created_at).format("DD MMM")}</div>
          </div>
          <div className="nk-reply-body">
            <Card sx={{ boxShadow: "none" }} variant="outlined" className={`nk-reply-entry entry note rounded-3 p-2`}>
              <Typography>{reply}</Typography>
            </Card>
            {fileUploads.length > 0 ? (
              <div className="attach-files">
                <div className="d-flex align-items-center" style={{ gap: "0.625rem" }}>
                  <ul className="attach-list">
                    {fileDetails.map((att, index) => (
                      <li className="attach-item" key={index}>
                        <Box className="download" width={"100%"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            gap={"1.25rem"}
                          >
                            <Stack direction={"row"} gap={"0.425rem"} alignItems={"center"}>
                              <AttachFile color="primary" sx={{ fontSize: "1.4rem" }} />
                              <span>{att.filename}</span>
                            </Stack>
                            <IconButton
                              sx={{
                                "&:hover": {
                                  color: styleVar.errorMain,
                                },
                              }}
                              onClick={() => downloadAttachments(att.path, index, att.filename || "")}
                              disabled={fileAttIdx === index && downTicketAttachmentIsPending}
                            >
                              {fileAttIdx === index && downTicketAttachmentIsPending ? (
                                <CircularProgress size={24} color="primary" />
                              ) : (
                                <FileDownloadOutlined />
                              )}
                            </IconButton>
                          </Stack>
                        </Box>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="attach-foot">
                  <span className="attach-info">{fileUploads.length} files attached</span>
                  <a
                    className="attach-download link d-none"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      downloadAttachments("");
                    }}
                  >
                    <Icon name="download" />
                    <span>Download All</span>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
            <Card variant="outlined" className="nk-reply-from">
              {"Replied"} by <span>{item.user.username}</span> at {new Date(item.created_at).toDateString()}
            </Card>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export const MetaItem = ({ item }) => {
  return <div className="nk-reply-meta">{parse(item)}</div>;
};
