import { useEffect } from "react";
import Swal from "sweetalert2";

import styles from "./SwalWithCustomButtons.module.css";

const ErrorAlert = ({ title, description, status, clg, isConformed }) => {
  useEffect(() => {
    const swalWithCustomButtons = Swal.mixin({
      customClass: {
        confirmButton: `${styles.btn} ${styles["success-btn"]}`,
        cancelButton: `${styles.btn} ${styles["danger-btn"]}`,
      },
      buttonsStyling: false,
    });

    swalWithCustomButtons.fire(title, description, status || "error").then((result) => {
      if (result.isConfirmed) {
        isConformed && isConformed();
      }
    });

    if (clg) {
      console.log(clg);
    }
    //eslint-disable-next-line
  }, [title, description, status]);

  return null;
};

export default ErrorAlert;
