import { getCookie } from "../../utils/Utils";

//^ CREATE TICKET HTTP Requests =============================================================

export async function getTitleQuestionAnswerHandler({ titleId }) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}help/question-answer`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken", null),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title_id: titleId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error("An Error occurred while fetching title's question answer.");
      error.code = response.status;
      error.info = errorData;
      throw error;
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching titles:", error);
    throw error;
  }
}

export async function createTicketHandler(formData) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}support/add-ticket`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken", undefined),
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();

      const error = new Error("An Error occurred while creating a new ticket.");
      error.info = errorData;
      error.code = response.status;

      throw error;
    }

    const resData = await response.json();

    return resData;
  } catch (error) {
    throw error;
  }
}

//^ CREATE TICKET HTTP Requests =============================================================

//^ TICKET HTTP Requests =============================================================

export async function changeStatusTicketHandler({ ticket_id, status, type }) {
  const data = {
    ticket_id, // ticket id
    status, // status of "0" or "1"
    type, // type of "status" or "is_stared"
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}support/change-status-ticket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authToken: getCookie("authToken", undefined),
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error("An Error occurred while changing the status of a ticket.");
      error.info = errorData;
      error.status = response.status;
      throw error;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

export async function getTicketHandler({ ticketId }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}support/get-ticket`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", undefined),
    },
    body: JSON.stringify({ ticket_id: ticketId }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while fetching a ticket.");
    error.info = errorData;
    error.status = response.status;
    throw error;
  }

  return await response.json();
}

export async function downloadTicketAttachment({ ticketId, file_path, type, reply_id }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}support/download-ticket-attachment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", undefined),
    },
    body: JSON.stringify({ db_id: ticketId, file_path, type, reply_id }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while downloading the attachment.");
    error.info = errorData;
    error.status = response.status;
    throw error;
  }

  return await response.blob();
}

export async function replayTicketHandler({ ticketId, reply, files, chatType }) {
  try {
    const formData = new FormData();
    formData.append("ticket_id", ticketId);
    formData.append("reply", reply);
    formData.append(
      "file_upload",
      files.forEach((file, index) => formData.append(`file_upload[${index}]`, file))
    );
    formData.append("chat_type", chatType);

    const response = await fetch(`${process.env.REACT_APP_API_URL}support/reply-ticket`, {
      method: "POST",
      headers: { authToken: getCookie("authToken", undefined) },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error("An Error occurred while replaying a ticket.");
      error.info = errorData;
      error.status = response.status;
      throw error;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

export async function getTicketSummaryHandler({ duration = 30, duration_type = "d" }) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}support/get-ticket-summary`, {
      method: "POST",
      headers: { "Content-Type": "application/json", authToken: getCookie("authToken", undefined) },
      body: JSON.stringify({ duration_type, duration: duration }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error("An Error occurred while getting the ticket summary.");
      error.info = errorData;
      error.status = response.status;
      throw error;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

//^ TICKET HTTP Requests =============================================================

//^ TICKET HTTP Requests =============================================================

//^ TICKET HTTP Requests =============================================================

//^ Assign User HTTP Requests =============================================================

export async function getAllAssignUsersHandler({ limit = 5, offset = 0, search, ticket_id, assignUser }) {
  let data = {
    limit,
    offset,
    ticket_id,
    assignUser,
  };

  if (search) {
    data = {
      limit,
      offset,
      search,
      ticket_id,
    };
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}support/get-tech-users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", undefined),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting all assign users.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function assignUserHandler(data) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}support/assign-tech-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", undefined),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while assigning a user.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ Assign User HTTP Requests =============================================================

//^ User HTTP Requests =============================================================

export async function getUserRoleHandler() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}public/get-role`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", undefined),
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting user role.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ User HTTP Requests =============================================================

//^ Notification http request =================================================================

export async function getAllNotificationHandler({ limit = 10, offset = 0, module = 3, recent = 10 }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}public/get-notifications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", undefined),
    },
    body: JSON.stringify({ limit, offset, module, recent }),
  });

  if (!response.ok) {
    const errorData = await response.json();

    const error = new Error("An Error occurred while getting all notifications.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function setNotificationCountHandler({ status = 0, notification_id: notifcation_id = 0 }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}public/set-notification-status`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", undefined),
    },
    body: JSON.stringify({ status, notifcation_id }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while setting notification count.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function getProfileImageHandler({ type = "crop" }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}account/get-profile-img`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ type }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting profile image.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  if (response.status === 200) {
    return await response.blob();
  } else if (response.status === 204) {
    return await response.json();
  }
}
