export default function TicketNotFoundTwo({ className }) {
  return (
    <svg
      width="360"
      height="654"
      viewBox="0 0 360 654"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M233.84 357.073C228.173 353.156 223.046 349.423 218.863 344.422C216.615 341.758 215.11 339.725 214.773 336.19C214.548 333.491 214.663 330.762 215.112 328.093C215.915 323.126 217.191 318.217 218.908 313.479L222.791 314.508C222.174 319.232 221.584 324.049 221.458 328.709C221.185 333.627 221.187 335.893 224.588 339.486C227.286 342.389 234.103 347.209 238.421 350.378L233.84 357.073H233.84Z"
        fill="#FFC3BD"
      />
      <path
        d="M252.287 357.703C252.036 354.131 238.425 349.197 238.425 349.197L230.313 357.439C230.313 357.439 242.842 369.022 247.068 367.093C251.494 365.058 252.649 362.913 252.287 357.704L252.287 357.703Z"
        fill="#407BFF"
      />
      <path
        d="M252.287 357.703C252.036 354.131 238.425 349.197 238.425 349.197L230.313 357.439C230.313 357.439 242.842 369.022 247.068 367.093C251.494 365.058 252.649 362.913 252.287 357.704L252.287 357.703Z"
        fill="#E14954"
      />
      <path
        d="M252.287 357.703C252.036 354.131 238.425 349.197 238.425 349.197L230.313 357.439C230.313 357.439 242.842 369.022 247.068 367.093C251.494 365.058 252.649 362.913 252.287 357.704L252.287 357.703Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path d="M249.092 357.436L235.422 352.255L230.876 356.888L249.092 357.436Z" fill="black" fillOpacity="0.301961" />
      <path d="M263.568 597.985H255.156L254.589 578.502H263.009L263.568 597.985Z" fill="#FFC3BD" />
      <path d="M337.806 558.907L331.395 564.36L316.722 551.26L323.133 545.816L337.806 558.907Z" fill="#FFC3BD" />
      <path
        d="M329.637 563.709L335.861 556.581C335.989 556.434 336.173 556.35 336.368 556.35C336.498 556.35 336.627 556.388 336.736 556.461L343.072 560.563C343.435 560.815 343.652 561.23 343.652 561.671C343.652 562 343.532 562.317 343.314 562.564C341.1 565.011 339.909 566.081 337.173 569.207C335.498 571.133 333.238 574.129 330.911 576.781C328.585 579.433 325.831 577.293 326.567 575.972C329.832 570.072 329.05 568.007 329.106 565.104C329.114 564.592 329.302 564.097 329.636 563.709V563.709L329.637 563.709Z"
        fill="#263238"
      />
      <path
        d="M254.868 597.018H264.322C264.324 597.017 264.326 597.017 264.328 597.017C264.662 597.017 264.945 597.263 264.992 597.594L266.071 605.038C266.081 605.105 266.086 605.174 266.086 605.243C266.086 605.988 265.481 606.592 264.737 606.592C264.719 606.592 264.702 606.591 264.684 606.59C261.39 606.535 259.799 606.339 255.64 606.339C253.081 606.339 247.796 606.609 244.26 606.609C240.725 606.609 240.539 603.111 242.018 602.804C248.605 601.381 251.201 599.427 253.425 597.556C253.825 597.209 254.338 597.017 254.868 597.017V597.017L254.868 597.018Z"
        fill="#263238"
      />
      <path d="M254.599 578.512L254.887 588.551H263.298L263.009 578.512H254.599Z" fill="black" fillOpacity="0.2" />
      <path
        d="M323.142 545.816L316.722 551.26L324.287 558.014L330.707 552.562L323.142 545.816Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M244.81 357.815C244.81 357.815 240.8 359.23 248.83 408.598H282.995C282.427 394.688 279.385 386.487 286.028 357.951C281.256 356.974 279.412 355.937 274.542 355.652C271.972 355.487 269.373 355.381 266.787 355.381C264.201 355.381 261.602 355.487 259.029 355.66C252.366 356.308 244.81 357.815 244.81 357.815Z"
        fill="#E14954"
      />
      <path
        d="M244.475 357.815C244.475 357.815 240.465 359.23 248.494 408.598H282.669C282.092 394.688 279.427 386.865 286.079 358.329C281.306 357.341 279.083 355.938 274.215 355.651C271.648 355.487 269.039 355.381 266.456 355.381C263.874 355.381 261.264 355.487 258.695 355.66C252.03 356.308 244.475 357.815 244.475 357.815Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M274.546 355.656C271.972 355.487 269.373 355.38 266.787 355.38C264.201 355.38 261.602 355.487 259.029 355.66C258.192 355.735 257.336 355.827 256.489 355.93C256.039 356.604 255.798 357.4 255.798 358.213C255.798 358.67 255.874 359.123 256.022 359.555C257.111 362.263 261.037 362.579 263.902 362.579C274.481 362.579 276.267 356.401 276.342 356.14L276.444 355.769C275.849 355.731 275.18 355.684 274.547 355.656H274.546Z"
        fill="white"
      />
      <path
        d="M273.058 338.193C272.072 343.18 271.104 352.326 274.594 355.657C274.594 355.657 273.226 360.728 263.95 360.728C253.715 360.728 259.074 355.666 259.074 355.666C264.657 354.336 264.498 350.205 263.531 346.362L273.058 338.193V338.193Z"
        fill="#FFC3BD"
      />
      <path
        d="M271.878 344.493L263.531 346.362C263.4 347.297 264.105 348.192 264.045 349.568C266.119 350.11 269.846 349.019 270.925 347.06C271.105 346.76 271.266 346.447 271.401 346.125C271.623 345.601 271.782 345.053 271.877 344.493H271.878Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M280.219 316.109C280.561 313.073 281.686 308.538 287.297 308.643C286.389 305.553 287.756 301.041 291.169 299.461C294.583 297.882 297.683 300.3 297.683 300.3C297.683 300.3 301.354 294.467 308.502 298.805C315.65 303.142 310.139 306.725 310.139 306.725C310.139 306.725 321.406 309.055 317.978 319.12C314.55 329.186 309.09 325.469 309.09 325.469C309.09 325.469 312.086 331.317 307.71 334.511C303.333 337.704 298.015 333.723 298.015 333.723C298.015 333.723 292.03 340.105 287.262 333.025C282.493 325.944 280.22 316.109 280.22 316.109H280.219Z"
        fill="#263238"
      />
      <path
        d="M262.375 315.298C258.4 317.539 257.944 321.78 259.616 324.232C263.656 330.096 277.903 318.656 276.568 316.003C275.234 313.35 266.354 313.048 262.375 315.298Z"
        fill="#263238"
      />
      <path
        d="M288.94 317.172C285.923 314.174 281.589 314.153 279.508 315.88C274.43 320.09 283.064 335.173 289.593 329.99C293.468 326.908 292.05 320.261 288.94 317.173L288.94 317.172Z"
        fill="#E14954"
      />
      <path
        d="M280.448 333.019C277.46 340.847 276.374 344.186 271.073 347.008C263.112 351.254 254.177 345.89 254.768 337.354C255.302 329.672 260.086 318.209 268.862 317.489C270.711 317.328 272.572 317.62 274.282 318.341C279.983 320.745 282.722 327.267 280.448 333.019L280.448 333.019Z"
        fill="#FFC3BD"
      />
      <path
        d="M277.588 336.569C274.697 333.845 275.529 329.48 276.743 328.134C275.057 327.342 271.571 325.145 271.07 322.429C267.672 323.016 261.299 322.347 261.894 319.236C262.49 316.124 274.107 312.381 280.792 314.534C288.739 317.098 293.125 327.814 288.138 334.203C283.151 340.592 277.587 336.569 277.587 336.569H277.588Z"
        fill="#263238"
      />
      <path
        d="M248.867 408.598C248.867 408.598 242.232 473.402 242.781 499.062C243.358 525.747 253.137 586.606 253.137 586.606H264.507C264.507 586.606 262.711 527.301 263.716 501.101C264.814 472.527 273.885 408.598 273.885 408.598H248.866H248.867Z"
        fill="#263238"
      />
      <path d="M265.456 587.016H252.114L251.127 582.764L266.08 582.233L265.456 587.016Z" fill="#407BFF" />
      <path
        d="M263.568 424.722C255.194 441.6 261.883 472.397 264.852 483.859C266.368 466.647 268.936 445.61 270.937 430.164C269.671 421.437 267.475 416.934 263.568 424.722Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M257.855 408.598C257.855 408.598 265.596 475.97 271.439 497.602C278.38 523.282 321.486 557.847 321.486 557.847L330.232 550.403C330.232 550.403 298.989 512.851 293.676 499.323C282.353 470.48 292.047 427.42 282.994 408.579L257.855 408.597V408.598Z"
        fill="#263238"
      />
      <path d="M331.628 550.022L320.779 559.233L316.722 556.805L328.995 545.389L331.628 550.022Z" fill="#407BFF" />
      <path
        d="M267.678 331.43C267.475 332.081 266.967 332.493 266.546 332.366C266.125 332.238 265.942 331.596 266.134 330.95C266.325 330.304 266.848 329.878 267.274 330.017C267.7 330.156 267.893 330.753 267.678 331.43L267.678 331.43Z"
        fill="#263238"
      />
      <path
        d="M260.363 329.142C260.162 329.784 259.651 330.206 259.222 330.074C258.793 329.944 258.626 329.308 258.813 328.65C259 327.992 259.524 327.586 259.942 327.722C260.359 327.858 260.567 328.491 260.363 329.142L260.363 329.142Z"
        fill="#263238"
      />
      <path d="M260.132 327.822L258.72 326.884C258.72 326.884 259.183 328.372 260.132 327.822Z" fill="#263238" />
      <path
        d="M261.941 330.858C260.904 332.151 259.69 333.302 258.342 334.267C258.654 334.678 259.069 335.001 259.543 335.201C259.853 335.331 260.183 335.408 260.518 335.428L261.941 330.857V330.858Z"
        fill="#ED847E"
      />
      <path
        d="M262.35 339.129C261.983 339.24 261.623 339.384 261.281 339.556C261.233 339.578 261.196 339.617 261.176 339.664C261.156 339.712 261.154 339.766 261.172 339.814C261.189 339.865 261.226 339.905 261.276 339.926C261.325 339.947 261.381 339.946 261.429 339.922C262.859 339.167 264.555 339.093 266.046 339.722C266.126 339.755 266.207 339.792 266.285 339.83C266.293 339.835 266.301 339.839 266.31 339.842C266.41 339.885 266.526 339.838 266.568 339.737C266.606 339.646 266.573 339.542 266.488 339.491C266.373 339.434 266.255 339.378 266.136 339.328C264.936 338.822 263.595 338.752 262.349 339.129L262.35 339.128L262.35 339.129Z"
        fill="#263238"
      />
      <path
        d="M280.003 339.102C279.193 340.498 277.862 341.518 276.304 341.935C274.229 342.474 273.226 340.637 273.838 338.684C274.407 336.904 276.109 334.593 278.188 334.884C278.371 334.92 278.55 334.975 278.722 335.048C280.143 335.647 280.809 337.285 280.21 338.705C280.152 338.843 280.082 338.976 280.003 339.102L280.003 339.102Z"
        fill="#FFC3BD"
      />
      <path
        d="M270.291 327.341C270.258 327.337 270.226 327.328 270.195 327.315C270.128 327.286 270.069 327.239 270.026 327.179C269.684 326.682 269.204 326.292 268.647 326.057C268.282 325.903 267.89 325.819 267.494 325.808C267.436 325.81 267.378 325.799 267.324 325.776C267.181 325.715 267.083 325.582 267.07 325.427C267.067 325.368 267.078 325.309 267.101 325.255C267.16 325.114 267.295 325.019 267.447 325.011C267.965 325.015 268.478 325.121 268.955 325.321C269.646 325.613 270.241 326.097 270.668 326.713C270.748 326.823 270.765 326.968 270.712 327.094C270.684 327.16 270.638 327.218 270.579 327.26C270.496 327.322 270.394 327.352 270.292 327.341V327.341L270.291 327.341Z"
        fill="#263238"
      />
      <path
        d="M258.16 324.739C258.109 324.738 258.059 324.726 258.012 324.707C257.965 324.686 257.923 324.658 257.886 324.624C257.799 324.507 257.78 324.353 257.837 324.219C257.861 324.159 257.901 324.106 257.95 324.065C258.927 323.23 260.252 322.925 261.496 323.248C261.508 323.251 261.52 323.256 261.532 323.261C261.721 323.341 261.816 323.554 261.749 323.748C261.746 323.757 261.742 323.766 261.739 323.776C261.658 323.967 261.446 324.064 261.249 324.001C260.261 323.767 259.22 324.02 258.449 324.679C258.365 324.738 258.26 324.76 258.16 324.739Z"
        fill="#263238"
      />
      <path
        d="M327.339 567.765C326.99 567.756 326.664 567.587 326.455 567.309C326.33 567.181 326.26 567.009 326.26 566.83C326.26 566.765 326.269 566.701 326.287 566.639C326.603 565.616 329.525 564.778 329.86 564.686C329.878 564.679 329.899 564.675 329.919 564.675C329.977 564.675 330.031 564.703 330.064 564.751C330.085 564.783 330.096 564.819 330.096 564.858C330.096 564.896 330.085 564.933 330.064 564.965C329.543 565.96 328.51 567.635 327.45 567.756L327.338 567.766L327.339 567.765ZM329.497 565.206C328.343 565.579 326.836 566.221 326.705 566.761C326.699 566.785 326.696 566.81 326.696 566.835C326.696 566.916 326.73 566.994 326.79 567.048C326.929 567.248 327.157 567.367 327.4 567.367C327.42 567.367 327.44 567.366 327.46 567.364C327.999 567.3 328.753 566.499 329.497 565.206L329.497 565.206Z"
        fill="#407BFF"
      />
      <path
        d="M328.576 565.243C328.461 565.255 328.344 565.261 328.227 565.261C327.472 565.261 326.738 565.013 326.139 564.555C326.041 564.432 325.988 564.279 325.988 564.122C325.988 563.965 326.041 563.812 326.139 563.69C326.306 563.428 326.584 563.257 326.892 563.224C328.102 563.076 329.934 564.657 330.009 564.722C330.053 564.757 330.079 564.811 330.079 564.867C330.079 564.881 330.078 564.894 330.075 564.908C330.067 564.982 330.015 565.044 329.944 565.067C329.53 565.184 329.101 565.244 328.67 565.244C328.639 565.244 328.607 565.243 328.576 565.243L328.577 565.243H328.576ZM327.097 563.615H326.966C326.774 563.636 326.6 563.742 326.491 563.903C326.352 564.117 326.398 564.229 326.491 564.303C326.854 564.777 328.51 564.954 329.516 564.768C328.828 564.174 327.992 563.773 327.096 563.614V563.615H327.097Z"
        fill="#407BFF"
      />
      <path
        d="M251.704 598.488C251.611 598.496 251.515 598.501 251.42 598.501C250.796 598.501 250.185 598.315 249.667 597.966C249.433 597.753 249.3 597.451 249.3 597.135C249.3 597.102 249.301 597.069 249.304 597.036C249.319 596.812 249.45 596.611 249.648 596.506C250.681 595.938 254.03 597.65 254.412 597.855C254.476 597.891 254.516 597.959 254.516 598.033C254.516 598.042 254.515 598.05 254.514 598.059C254.504 598.144 254.44 598.212 254.356 598.227C253.483 598.394 252.593 598.483 251.704 598.488H251.704ZM250.207 596.748C250.191 596.746 250.175 596.746 250.16 596.746C250.047 596.746 249.935 596.772 249.834 596.822C249.755 596.861 249.704 596.942 249.704 597.031C249.704 597.032 249.704 597.034 249.704 597.036C249.7 597.067 249.697 597.098 249.697 597.13C249.697 597.335 249.784 597.531 249.936 597.669C250.448 598.134 251.797 598.208 253.658 597.901C252.584 597.323 251.414 596.931 250.207 596.747L250.206 596.748H250.207Z"
        fill="#407BFF"
      />
      <path
        d="M254.319 598.218H254.236C253.23 597.763 251.249 595.948 251.444 594.999C251.444 594.776 251.639 594.487 252.189 594.431C252.239 594.426 252.291 594.423 252.341 594.423C252.691 594.423 253.029 594.548 253.296 594.775C254.347 595.659 254.542 597.92 254.552 598.022C254.552 598.028 254.553 598.033 254.553 598.038C254.553 598.102 254.521 598.163 254.469 598.199C254.44 598.217 254.408 598.226 254.374 598.226C254.356 598.226 254.337 598.223 254.32 598.217V598.218H254.319ZM252.309 594.804H252.198C251.825 594.804 251.807 594.99 251.797 595.036C251.704 595.603 253.053 597.037 254.077 597.65C253.994 596.695 253.616 595.786 252.998 595.054C252.81 594.881 252.564 594.782 252.309 594.775L252.31 594.804L252.309 594.804Z"
        fill="#407BFF"
      />
      <path
        d="M283.507 406.551L284.381 409.612C284.502 409.845 284.223 410.087 283.823 410.087H248.662C248.355 410.087 248.095 409.938 248.076 409.743L247.769 406.691C247.769 406.477 248.011 406.3 248.355 406.3H282.958C282.974 406.298 282.992 406.298 283.01 406.298C283.206 406.298 283.391 406.392 283.506 406.551H283.507V406.551Z"
        fill="#407BFF"
      />
      <path
        d="M283.507 406.551L284.381 409.612C284.502 409.845 284.223 410.087 283.823 410.087H248.662C248.355 410.087 248.095 409.938 248.076 409.743L247.769 406.691C247.769 406.477 248.011 406.3 248.355 406.3H282.958C282.974 406.298 282.992 406.298 283.01 406.298C283.206 406.298 283.391 406.392 283.506 406.551H283.507V406.551Z"
        fill="white"
        fillOpacity="0.301961"
      />
      <path
        d="M278.864 410.458H279.794C279.971 410.458 280.11 410.365 280.101 410.244L279.663 406.271C279.663 406.159 279.497 406.057 279.31 406.057L278.38 406.057C278.194 406.057 278.054 406.159 278.073 406.272L278.501 410.245C278.519 410.365 278.64 410.458 278.864 410.458H278.864Z"
        fill="#263238"
      />
      <path
        d="M251.268 410.458H252.197C252.384 410.458 252.523 410.365 252.514 410.244L252.077 406.271C252.077 406.159 251.9 406.057 251.723 406.057L250.793 406.057C250.607 406.057 250.467 406.159 250.476 406.272L250.914 410.245C250.923 410.365 251.081 410.458 251.268 410.458Z"
        fill="#263238"
      />
      <path
        d="M265.065 410.458H265.996C266.182 410.458 266.321 410.365 266.312 410.244L265.875 406.271C265.875 406.159 265.707 406.057 265.521 406.057L264.591 406.057C264.405 406.057 264.265 406.159 264.284 406.272L264.684 410.272C264.684 410.365 264.879 410.458 265.065 410.458Z"
        fill="#263238"
      />
      <path d="M267.456 330.114L266.044 329.175C266.044 329.175 266.455 330.641 267.456 330.114Z" fill="#263238" />
      <path
        d="M205.209 95.1147L161.174 142.407C158.624 140.026 154.864 139.466 151.731 141.003C150.813 141.454 149.983 142.07 149.286 142.817L62.47 61.9824C64.8497 59.4341 65.4084 55.6757 63.8726 52.5442C63.4218 51.6252 62.8046 50.7939 62.0554 50.0963L106.08 2.81004C108.631 5.1815 112.382 5.73494 115.51 4.20042C116.424 3.75181 117.251 3.13809 117.947 2.39551L204.773 83.2391C202.406 85.7892 201.854 89.5357 203.386 92.6593C203.839 93.5815 204.458 94.4153 205.21 95.1138V95.1142L205.209 95.1147Z"
        fill="#CCBE9F"
      />
      <path
        d="M200.092 94.0388L159.737 137.385C157.188 134.997 153.426 134.435 150.29 135.973C149.368 136.426 148.535 137.045 147.836 137.797L67.5727 63.067C69.9472 60.5182 70.502 56.7656 68.9675 53.638C68.5154 52.7168 67.8986 51.8855 67.1477 51.1862L107.504 7.84387C110.052 10.2206 113.806 10.7766 116.936 9.24167C117.861 8.78783 118.694 8.16624 119.395 7.41187L199.669 82.1558C197.293 84.7041 196.737 88.4585 198.272 91.5879C198.724 92.5082 199.343 93.3399 200.092 94.0384L200.092 94.0388Z"
        stroke="#513228"
        strokeWidth="0.999864"
        strokeMiterlimit="22.9256"
      />
      <path
        d="M97.4893 49.6075L108.352 37.9465L104.187 34.0671L106.399 31.6928L117.552 42.0825L115.341 44.4568L111.186 40.5863L100.323 52.2473L97.4893 49.6075ZM106.23 57.7499L119.304 43.7145L122.138 46.3543L109.064 60.3897L106.23 57.7499ZM125.561 66.1185L127.497 69.5492C125.649 70.6887 123.885 71.174 122.207 71.0051C120.541 70.8358 118.954 70.0496 117.448 68.6465C115.584 66.9104 114.644 64.8484 114.627 62.4605C114.617 60.0663 115.64 57.7649 117.697 55.5566C119.873 53.2205 122.165 51.9697 124.572 51.8041C126.985 51.6322 129.175 52.4618 131.141 54.293C132.857 55.8923 133.779 57.6988 133.906 59.7125C133.986 60.907 133.662 62.2146 132.934 63.6352L129.505 61.6923C130.017 60.8101 130.204 59.9117 130.067 58.9969C129.936 58.0882 129.494 57.283 128.741 56.5814C127.7 55.6123 126.507 55.1979 125.16 55.3382C123.82 55.4845 122.371 56.3938 120.813 58.066C119.16 59.8404 118.302 61.4014 118.239 62.7491C118.176 64.0968 118.655 65.2463 119.676 66.1975C120.429 66.8991 121.3 67.2632 122.288 67.2899C123.277 67.3166 124.367 66.9261 125.561 66.1185ZM125.981 76.1482L139.055 62.1128L141.889 64.7526L136.083 70.9852L147.614 70.0857L151.425 73.6352L141.048 74.179L138.638 87.9381L134.971 84.5224L137.249 74.3415L132.766 74.5467L128.815 78.788L125.981 76.1482ZM140.112 89.3115L153.186 75.2761L163.593 84.9703L161.382 87.3446L153.809 80.2903L150.91 83.4018L157.957 89.9656L155.754 92.3303L148.707 85.7665L145.149 89.5865L152.99 96.8905L150.787 99.2553L140.112 89.3115ZM156.35 104.437L167.212 92.7758L163.047 88.8963L165.259 86.522L176.413 96.9118L174.201 99.2861L170.046 95.4156L159.184 107.077L156.35 104.437Z"
        fill="#352A13"
      />
      <path
        d="M81.8457 44.7746C80.7196 43.7459 80.5702 42.7203 81.2586 41.9629C81.947 41.2046 82.9844 41.2644 84.114 42.2914C85.2436 43.3183 85.4179 44.3649 84.7256 45.125C84.0332 45.885 82.9674 45.7963 81.8457 44.7751V44.7746ZM83.613 42.8378C82.6988 42.0061 82.0986 41.9996 81.7351 42.3979C81.3717 42.7959 81.4364 43.3952 82.3462 44.2282C83.2566 45.0616 83.8856 45.0874 84.2494 44.6895C84.6129 44.2915 84.5238 43.6713 83.6134 42.8378H83.613ZM82.6293 43.8696C82.597 43.8351 82.5703 43.7957 82.5494 43.7534C82.4314 43.5131 82.531 43.2226 82.7708 43.1047C83.0111 42.9868 83.3016 43.0864 83.4195 43.3262C83.506 43.5031 83.4772 43.7145 83.3466 43.8617C83.3046 43.9106 83.2526 43.9513 83.1941 43.9801C83.0023 44.074 82.7717 44.029 82.6289 43.8696V43.8691L82.6293 43.8696Z"
        fill="#F9324A"
      />
      <path
        d="M86.044 42.729L85.9204 42.0878C86.1401 42.0695 86.3537 42.0105 86.5516 41.9135C86.749 41.8165 86.9268 41.6833 87.0757 41.5213C87.4488 41.1133 87.537 40.6406 87.2242 40.3545C86.9119 40.0684 86.3995 40.1143 85.6456 40.9425L85.2057 40.5424C85.8631 39.8186 85.8859 39.3394 85.581 39.0589C85.2765 38.7785 84.9301 38.9004 84.5999 39.2393C84.3505 39.5316 84.2142 39.9037 84.2159 40.2873L83.562 40.2593C83.5716 39.7111 83.7769 39.1826 84.1412 38.7715C84.7641 38.0936 85.5312 37.894 86.0925 38.4068C86.4659 38.7488 86.4699 39.2442 86.2357 39.7692L86.2471 39.7928C86.3244 39.7255 86.4113 39.6683 86.5039 39.6233C86.9255 39.4167 87.4282 39.4713 87.7952 39.764C88.401 40.3174 88.2254 41.2251 87.6003 41.9087C87.3972 42.1616 87.1408 42.3674 86.8499 42.5102C86.5979 42.6338 86.3244 42.7081 86.0449 42.7295H86.0444L86.044 42.729Z"
        fill="#F9324A"
      />
      <path
        d="M89.1374 39.1721L89.9844 38.2404L87.7982 36.2467L87.1211 36.9893L86.7358 36.6429C86.9949 36.2974 87.1818 35.9034 87.2862 35.4849L87.6942 35.0411L90.5379 37.6345L91.2804 36.8211L91.7736 37.2715L89.631 39.622L89.137 39.1716L89.1374 39.1721Z"
        fill="#F9324A"
      />
      <path
        d="M92.0466 36.1734L91.9155 35.5353C92.1317 35.5182 92.3423 35.461 92.5367 35.3653C92.7311 35.2701 92.9053 35.1386 93.0512 34.9783C93.3614 34.714 93.4535 34.2724 93.274 33.9064C93.2277 33.8125 93.1657 33.7277 93.0901 33.6552C92.8298 33.4237 92.455 33.3726 92.1418 33.5259C92.0129 33.5888 91.902 33.684 91.8194 33.8011C91.6176 34.0182 91.4853 34.2917 91.4394 34.5848L90.9877 34.7058L89.427 33.083L91.2219 31.1134L91.736 31.5873L90.4229 33.0293L91.1594 33.8291C91.2389 33.5976 91.3656 33.3853 91.532 33.2066C92.1047 32.5824 92.9023 32.3583 93.6204 33.0135C94.3385 33.6692 94.2136 34.6712 93.6069 35.3321C93.3972 35.5916 93.1325 35.803 92.8324 35.9502C92.5865 36.0712 92.3196 36.1468 92.047 36.1734L92.0466 36.1739V36.1734Z"
        fill="#F9324A"
      />
      <path
        d="M95.1483 32.8655C95.1928 31.0094 95.1976 29.851 94.6835 29.3771C94.4607 29.1473 94.1135 29.087 93.8256 29.2286C93.7055 29.2871 93.6037 29.3779 93.5308 29.4898C93.2914 29.7925 93.1817 30.1782 93.225 30.5613L92.5881 30.587C92.5208 29.9965 92.7135 29.4046 93.1167 28.9669C93.7374 28.285 94.5625 28.2051 95.1775 28.7677C95.793 29.3303 95.8323 30.4717 95.8179 31.8739C95.9848 31.6585 96.1761 31.4166 96.3482 31.2318L97.2052 30.2909L97.7176 30.7609L95.4995 33.1913L95.1487 32.8663L95.1483 32.8655Z"
        fill="#F9324A"
      />
      <path
        d="M96.9459 28.4584C95.6547 27.2764 95.7364 26.0996 96.413 25.3758C96.579 25.1919 96.7786 25.0408 97.0005 24.932C97.2224 24.8228 97.4639 24.7573 97.7107 24.7394L97.7824 25.4016C97.6269 25.4164 97.4753 25.4592 97.3351 25.5278C97.1953 25.5964 97.0686 25.6903 96.9621 25.8039C96.5191 26.2936 96.4658 27.0029 97.4753 27.9403C98.3192 28.6869 98.9308 28.6973 99.3278 28.2592C99.5519 28.0172 99.6039 27.6621 99.4589 27.3659C99.4047 27.2559 99.3261 27.1598 99.2291 27.0851C99.0103 26.8487 98.6621 26.7841 98.3734 26.9261C98.2624 26.9802 98.1672 27.0619 98.0969 27.1628C97.8387 27.4786 97.7391 27.8958 97.8261 28.2946L97.3757 27.9325C97.336 27.4664 97.4784 27.0025 97.7728 26.6386C97.8947 26.4766 98.0532 26.3455 98.2349 26.256C98.7477 26.0048 99.3654 26.1215 99.7511 26.5425C99.8673 26.6587 99.9647 26.7941 100.037 26.9422C100.315 27.5101 100.206 28.1919 99.7638 28.6432C99.1465 29.3325 98.0654 29.4757 96.9454 28.4588L96.9459 28.4584Z"
        fill="#F9324A"
      />
      <path
        d="M100.312 22.2544L98.6704 24.0484L98.1584 23.5784L100.404 21.1156L100.777 21.4581C100.958 23.2084 101.49 24.0121 102.747 25.2684L102.182 25.8895C101.639 25.3946 101.193 24.8014 100.87 24.1423C100.579 23.5495 100.39 22.9096 100.313 22.2544H100.312Z"
        fill="#F9324A"
      />
      <path d="M79.3755 55.7609L113.082 19.6522" stroke="#513228" strokeWidth="0.999864" strokeMiterlimit="22.9256" />
      <path d="M154.163 125.573L187.869 89.4636" stroke="#513228" strokeWidth="0.999864" strokeMiterlimit="22.9256" />
      <path
        d="M185.4 100.45C186.525 101.479 186.675 102.505 185.986 103.263C185.298 104.021 184.261 103.961 183.131 102.934C182.002 101.907 181.827 100.861 182.52 100.1C183.212 99.34 184.277 99.4291 185.4 100.45V100.45ZM183.632 102.388C184.546 103.219 185.146 103.225 185.51 102.827C185.874 102.43 185.809 101.83 184.899 100.997C183.988 100.164 183.359 100.138 182.995 100.536C182.632 100.934 182.721 101.554 183.631 102.388L183.632 102.388ZM184.616 101.355C184.648 101.39 184.674 101.429 184.696 101.472C184.813 101.711 184.714 102.002 184.474 102.12C184.234 102.238 183.943 102.139 183.825 101.898C183.739 101.722 183.768 101.51 183.898 101.363C183.94 101.314 183.992 101.273 184.051 101.245C184.243 101.151 184.474 101.196 184.616 101.355Z"
        fill="#F9324A"
      />
      <path
        d="M181.201 102.496L181.325 103.137C181.105 103.156 180.891 103.215 180.694 103.312C180.496 103.409 180.318 103.542 180.17 103.704C179.796 104.112 179.708 104.585 180.021 104.871C180.334 105.157 180.845 105.111 181.6 104.283L182.04 104.683C181.382 105.407 181.36 105.886 181.664 106.167C181.969 106.447 182.315 106.325 182.646 105.986C182.895 105.694 183.031 105.322 183.029 104.938L183.683 104.966C183.673 105.514 183.468 106.043 183.104 106.453C182.481 107.131 181.714 107.331 181.153 106.819C180.779 106.476 180.775 105.981 181.009 105.456L180.998 105.433C180.921 105.499 180.834 105.557 180.742 105.602C180.32 105.809 179.817 105.754 179.45 105.461C178.844 104.908 179.02 104.001 179.645 103.317C179.848 103.064 180.104 102.859 180.395 102.716C180.647 102.592 180.921 102.518 181.201 102.496H181.201H181.201Z"
        fill="#F9324A"
      />
      <path
        d="M178.107 106.053L177.26 106.985L179.447 108.979L180.124 108.236L180.509 108.582C180.25 108.928 180.063 109.322 179.959 109.74L179.551 110.184L176.706 107.591L175.964 108.404L175.471 107.954L177.613 105.603L178.107 106.054V106.053Z"
        fill="#F9324A"
      />
      <path
        d="M175.198 109.052L175.33 109.69C175.113 109.707 174.902 109.764 174.708 109.86C174.514 109.955 174.339 110.086 174.194 110.247C173.884 110.511 173.792 110.953 173.972 111.319C174.017 111.413 174.079 111.498 174.155 111.57C174.415 111.802 174.791 111.853 175.103 111.7C175.232 111.637 175.343 111.542 175.426 111.425C175.628 111.207 175.76 110.934 175.806 110.641L176.258 110.52L177.818 112.142L176.024 114.112L175.509 113.639L176.822 112.196L176.086 111.397C176.007 111.628 175.88 111.84 175.713 112.019C175.141 112.643 174.343 112.868 173.625 112.212C172.907 111.556 173.032 110.554 173.638 109.894C173.848 109.634 174.113 109.423 174.413 109.275C174.659 109.155 174.926 109.079 175.198 109.052Z"
        fill="#F9324A"
      />
      <path
        d="M172.097 112.36C172.052 114.216 172.047 115.374 172.561 115.848C172.785 116.078 173.132 116.138 173.42 115.997C173.54 115.938 173.642 115.848 173.715 115.736C173.954 115.433 174.064 115.047 174.02 114.664L174.657 114.638C174.724 115.229 174.532 115.821 174.129 116.258C173.508 116.94 172.683 117.02 172.067 116.457C171.452 115.895 171.413 114.753 171.427 113.351C171.261 113.567 171.069 113.809 170.897 113.994L170.04 114.935L169.528 114.465L171.746 112.034L172.097 112.359L172.097 112.36Z"
        fill="#F9324A"
      />
      <path
        d="M170.3 116.767C171.591 117.949 171.509 119.125 170.833 119.849C170.667 120.033 170.467 120.184 170.245 120.293C170.023 120.402 169.781 120.468 169.534 120.486L169.463 119.823C169.618 119.809 169.77 119.766 169.91 119.698C170.05 119.629 170.177 119.535 170.283 119.421C170.726 118.932 170.779 118.222 169.77 117.285C168.926 116.538 168.314 116.528 167.918 116.966C167.693 117.208 167.641 117.563 167.787 117.859C167.841 117.97 167.919 118.066 168.016 118.14C168.235 118.377 168.583 118.441 168.872 118.299C168.983 118.245 169.078 118.163 169.148 118.062C169.406 117.747 169.506 117.329 169.419 116.931L169.869 117.293C169.909 117.759 169.767 118.223 169.472 118.586C169.35 118.748 169.192 118.88 169.01 118.969C168.497 119.221 167.879 119.104 167.494 118.682C167.377 118.566 167.28 118.431 167.208 118.283C166.929 117.715 167.039 117.033 167.481 116.582C168.099 115.892 169.179 115.749 170.299 116.766L170.3 116.767Z"
        fill="#F9324A"
      />
      <path
        d="M166.932 122.971L168.574 121.177L169.087 121.647L166.842 124.11L166.468 123.767C166.288 122.017 165.755 121.213 164.499 119.957L165.063 119.336C165.606 119.831 166.052 120.424 166.376 121.084C166.666 121.676 166.856 122.316 166.932 122.971V122.971Z"
        fill="#F9324A"
      />
      <path d="M31.7651 592.076H357.417" stroke="#C5C6C6" strokeWidth="0.756123" strokeMiterlimit="22.9256" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.7303 600.395H120.269C120.269 600.395 124.589 588.516 110.01 572.855C95.4301 557.195 90.5697 562.055 85.1698 568.535C79.7699 575.015 63.03 585.275 65.7303 600.395V600.395Z"
        fill="#D9DADA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.0105 602.689H140.048C140.048 602.689 143.298 593.75 132.328 581.967C121.357 570.183 117.7 573.84 113.638 578.716C109.575 583.592 96.9784 591.312 99.0105 602.689Z"
        fill="#EBECEC"
      />
      <path
        d="M343.303 312.061L343.333 311.928C343.666 310.52 344.067 309.43 344.536 308.658C345.005 307.886 345.557 307.3 346.19 306.899C346.824 306.498 347.553 306.162 348.375 305.889C348.871 305.721 349.334 305.495 349.763 305.211C350.193 304.92 350.562 304.557 350.87 304.121C351.185 303.688 351.409 303.179 351.54 302.595C351.704 301.871 351.675 301.204 351.455 300.596C351.235 299.987 350.873 299.474 350.369 299.058C349.865 298.641 349.265 298.355 348.571 298.198C347.965 298.062 347.353 298.056 346.734 298.18C346.116 298.305 345.558 298.595 345.059 299.049C344.561 299.504 344.183 300.16 343.928 301.019L341.134 300.39C341.476 299.147 342.044 298.145 342.837 297.384C343.637 296.624 344.578 296.118 345.66 295.865C346.75 295.613 347.893 295.623 349.09 295.892C350.391 296.185 351.463 296.699 352.308 297.433C353.159 298.168 353.746 299.046 354.067 300.066C354.396 301.087 354.431 302.175 354.171 303.328C353.988 304.141 353.696 304.848 353.297 305.449C352.905 306.051 352.418 306.559 351.835 306.971C351.261 307.386 350.603 307.719 349.863 307.971C349.121 308.231 348.503 308.542 348.01 308.905C347.518 309.26 347.115 309.724 346.799 310.298C346.483 310.871 346.215 311.615 345.994 312.528L345.964 312.661L343.303 312.061ZM343.243 318.944C342.696 318.82 342.271 318.519 341.968 318.039C341.664 317.559 341.574 317.045 341.697 316.498C341.821 315.952 342.122 315.526 342.602 315.223C343.082 314.92 343.596 314.829 344.142 314.953C344.689 315.076 345.115 315.378 345.418 315.858C345.721 316.338 345.811 316.851 345.688 317.398C345.607 317.76 345.439 318.072 345.186 318.333C344.941 318.596 344.645 318.786 344.301 318.902C343.965 319.013 343.613 319.027 343.243 318.944Z"
        fill="#D9DADA"
      />
      <path
        d="M274.794 274.527L274.754 274.349C274.354 272.452 274.219 270.901 274.347 269.698C274.475 268.494 274.809 267.469 275.349 266.621C275.889 265.774 276.58 264.95 277.422 264.15C277.927 263.664 278.359 263.126 278.717 262.537C279.073 261.938 279.314 261.287 279.438 260.583C279.573 259.878 279.554 259.133 279.38 258.35C279.165 257.379 278.75 256.587 278.136 255.975C277.522 255.362 276.79 254.947 275.941 254.73C275.093 254.513 274.202 254.507 273.271 254.714C272.458 254.894 271.713 255.236 271.034 255.74C270.356 256.244 269.844 256.914 269.499 257.749C269.154 258.585 269.071 259.596 269.251 260.783L265.505 261.614C265.211 259.911 265.327 258.373 265.854 256.998C266.391 255.621 267.243 254.47 268.411 253.546C269.588 252.619 270.98 251.978 272.585 251.622C274.33 251.235 275.923 251.246 277.365 251.654C278.817 252.06 280.029 252.789 281.001 253.842C281.982 254.893 282.645 256.192 282.987 257.738C283.229 258.828 283.279 259.852 283.138 260.808C283.006 261.763 282.705 262.656 282.235 263.488C281.774 264.318 281.167 265.098 280.414 265.826C279.662 266.565 279.091 267.294 278.7 268.015C278.306 268.726 278.082 269.52 278.026 270.395C277.971 271.27 278.069 272.325 278.322 273.558L278.362 273.736L274.794 274.527ZM278.648 282.906C277.914 283.069 277.227 282.946 276.585 282.537C275.943 282.128 275.541 281.557 275.379 280.824C275.216 280.09 275.339 279.403 275.748 278.761C276.157 278.119 276.728 277.717 277.461 277.554C278.195 277.392 278.882 277.515 279.524 277.924C280.166 278.333 280.568 278.904 280.73 279.637C280.838 280.123 280.813 280.596 280.655 281.057C280.508 281.516 280.258 281.915 279.907 282.253C279.563 282.578 279.143 282.796 278.648 282.906Z"
        fill="#D9DADA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.126 159.472L178.495 198.837C178.495 198.837 189.717 218.191 189.913 231.31L221.927 265.538C221.927 265.538 226.034 274.273 225.131 279.502L250.87 304.289C250.87 304.289 251.345 312.584 249.416 316.023C247.486 319.462 240.062 327.63 231.557 329.88L202.299 313.742C202.299 313.742 192.937 316.953 188.698 315.129L145.55 291.092C145.55 291.092 122.878 296.463 109.702 288.28C96.5254 280.097 65.6462 262.205 65.6462 262.205L133.126 159.472L133.126 159.472Z"
        fill="#E14954"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.997 250.933C186.997 250.933 176.162 280.54 157.284 286.408C157.284 286.408 179.46 281.303 186.997 250.933Z"
        fill="#B43A43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.032 285.56C224.032 285.56 215.877 303.478 209.668 307.604C209.668 307.604 219.264 303.162 224.032 285.56Z"
        fill="#B43A43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.943 326.305L259.434 330.41L243.229 323.154C243.229 323.154 248.803 317.501 249.416 316.023L260.943 326.305H260.943Z"
        fill="#B43A43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.218 152.559C146.963 162.466 155.636 192.149 142.006 223.819C128.376 255.489 93.4193 274.458 67.0358 262.844C40.6519 251.231 32.4468 217.529 47.7575 187.358C63.0682 157.187 95.4745 142.652 121.218 152.559V152.559ZM118.436 161.439C139.663 169.572 146.518 194.711 134.863 221.791C123.209 248.871 92.1315 263.848 72.2823 255.815C52.4327 247.782 44.1228 217.678 57.1533 191.853C70.1843 166.029 97.2086 153.306 118.436 161.439Z"
        fill="#FF9198"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.6 159.857C122.238 161.642 125.595 164.655 128.915 167.781C126.261 165.791 123.907 163.642 120.581 162.363C98.2504 153.769 68.0007 168.484 56.8611 192.547C45.7215 216.611 51.1734 238.479 64.6993 251.256C48.8657 239.457 41.3848 214.665 53.8803 190.041C66.3757 165.417 95.2705 151.263 117.6 159.857Z"
        fill="#B43A43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.436 161.439C139.663 169.572 146.518 194.711 134.863 221.791C123.209 248.871 92.132 263.848 72.2819 255.815C52.4327 247.782 44.1228 217.678 57.1534 191.853C70.1839 166.029 97.2091 153.306 118.436 161.439Z"
        fill="url(#paint0_linear_118_73)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.696 164.966C136.334 172.49 142.676 195.748 131.894 220.8C121.112 245.852 91.3932 258.455 73.0301 251.024C54.6665 243.592 47.9474 216.995 60.0021 193.104C72.0569 169.213 97.0587 157.443 116.696 164.967V164.966Z"
        fill="url(#paint1_linear_118_73)"
      />
      <path
        d="M283.675 369.944C278.671 369.761 273.655 369.302 268.71 368.567C263.681 367.876 258.704 366.774 253.871 365.247C252.636 364.863 251.388 364.349 250.152 363.852L249.466 363.542C249.22 363.431 248.985 363.273 248.78 363.14C248.389 362.876 248.011 362.587 247.655 362.281C247.05 361.743 246.491 361.147 245.993 360.51C244.351 358.356 242.98 355.994 241.921 353.502C239.926 348.884 238.359 344.06 237.256 339.143L241.082 337.92C243.104 342.234 245.2 346.612 247.601 350.607C248.659 352.486 249.914 354.261 251.347 355.876C251.632 356.168 251.938 356.446 252.263 356.696C252.375 356.774 252.494 356.845 252.616 356.907C252.633 356.917 252.651 356.927 252.669 356.935C252.688 356.943 252.707 356.95 252.726 356.957L252.845 357.01C253.895 357.352 254.93 357.728 256.041 358.005C260.532 359.17 265.163 360.01 269.783 360.546C274.473 361.103 279.291 361.535 283.933 361.836L283.676 369.944H283.675Z"
        fill="#FFC3BD"
      />
      <path
        d="M290.758 370.149C288.399 372.841 275.963 371.733 275.963 371.733L272.791 355.708C272.791 355.708 283.748 355.341 287.191 358.48C290.751 361.784 293.449 367.087 290.758 370.149Z"
        fill="#E14954"
      />
      <path
        d="M290.774 370.115C288.414 372.807 275.982 371.69 275.982 371.69L272.791 355.708C272.791 355.708 283.749 355.341 287.191 358.48C290.782 361.716 293.45 367.087 290.774 370.115Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M241.563 339.117L244.019 330.364L236.233 333.9C236.233 333.9 235.836 339.296 238.766 341.172L241.563 339.117H241.563Z"
        fill="#FFC3BD"
      />
      <path d="M241.313 323.811L234.951 327.134L236.215 335.271L244.009 330.36L241.313 323.811Z" fill="#FFC3BD" />
      <defs>
        <linearGradient
          id="paint0_linear_118_73"
          x1="101.818"
          y1="152.441"
          x2="101.554"
          y2="263.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F86B0" />
          <stop offset="1" stopColor="#91BFD6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_118_73"
          x1="95.2464"
          y1="160.952"
          x2="96.1003"
          y2="251.55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C2E3E8" />
          <stop offset="1" stopColor="#9CD2DC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
