export const ticketTabsItem = [
  {
    id: 1,
    title: "Reply",
    value: "reply",
  },
  {
    id: 2,
    title: "Private Notes",
    value: "private notes",
  },
];

export const assignMembers = [
  {
    id: 50,
    role: "User",
    name: "Alissa Kate",
    theme: "purple",
  },
  {
    id: 51,
    role: "User",
    name: "Jasper Jordan",
    theme: "orange",
  },
  {
    id: 52,
    role: "User",
    name: "Winter Rays",
    theme: "pink",
  },
  {
    id: 53,
    role: "User",
    name: "Kayla Brown",
    theme: "blue",
  },
  {
    id: 54,
    role: "User",
    name: "Ray Dobolin",
    theme: "red",
  },
];
