import { useState, useEffect } from "react";

const useClientScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    //^ Check if we're in the browser environment
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setScreenSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      //^ Set initial screen size
      handleResize();

      //^ Add event listener for resize
      window.addEventListener("resize", handleResize);

      //^ Clean up event listener on unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return screenSize;
};

export default useClientScreenSize;
