import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment";
import classNames from "classnames";
import * as Yup from "yup";

//^ stylesheet
import styles from "./TicketDetail.module.scss";
import styleVar from "../../../assets/scss/_themes-vars.module.scss";

//^ redux action
import { ticketAction } from "../../../store/slices/ticket-slice";

//^ mui
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Card from "@mui/material/Card";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import CloseIcon from "@mui/icons-material/Close";

//^ static-data
import { ticketTabsItem } from "./ticket-detail-data";

//^ http request
import { queryClient } from "../../../http";
import {
  assignUserHandler,
  changeStatusTicketHandler,
  downloadTicketAttachment,
  getAllAssignUsersHandler,
  replayTicketHandler,
} from "../../../http/post-apis";

//^ lib
import { downloadBlob, getCookie, getInitials } from "../../../utils/Utils";

//^ components
import { Icon, UserAvatar } from "../../../components/Component";
import MessageProfileSidebar from "../../app/messages/MessageProfile";
import ErrorAlert from "../../../components/ui/model/error-model/ErrorModelV2";
import { ReplyItem } from "../../app/messages/MessagePartials";
import BreadCrumb from "../../../components/ui/bread-crumb/BreadCrumb";
import AssignUserSkeleton from "../../../components/ui/loading/Skeleton/AssignUserSkeleton";
import { useFormik } from "formik";
import { red } from "@mui/material/colors";
import FileUpload from "../../../components/ui/attachments/FileUpload";
import EmptyTicket from "../../../components/svg/empty-ticket";
import { useNavigate } from "react-router";

export const getFileIcon = (extension) => {
  if (["png", "jpg", "jpeg", "gif", "bmp", "svg"].includes(extension)) {
    return "img";
  } else if (["mp4", "avi", "mov", "mkv", "wmv"].includes(extension)) {
    return "video";
  } else if (["pdf"].includes(extension)) {
    return "pdf";
  } else {
    return "file";
  }
};

export default function TicketDetail({ ticketData, ticketRefetch, userRole }) {
  const theme = useTheme();

  const replyInputRef = useRef(null);
  const privateInputRef = useRef(null);
  const navigate = useNavigate();

  const [searchNm, setSearchNm] = useState("");
  const [replyFilter, setReplyFilter] = useState([]);
  const [replyFiles, setReplyFile] = useState({
    replyChat: [],
    privateChat: [],
  });

  useEffect(() => {
    if (ticketData) {
      const filteredReply = ticketData?.replies.reduce((acc, curr) => {
        const date = curr.created_at.split("T")[0];

        const index = acc.findIndex((group) => group.date === date);

        if (index !== -1) {
          acc[index].replies.push(curr);
        } else {
          acc.push({ date, replies: [curr] });
        }

        return acc;
      }, []);

      setReplyFilter(filteredReply);
    }
  }, [ticketData]);

  //^ redux selectors
  const mobileView = useSelector((state) => state.ticket.mobileView);
  const dispatch = useDispatch();

  //^ boolean states
  const [sidebar, setSideBar] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  //^ other states
  const [selectedAssignUsers, setSelectedAssignUsers] = useState([]);
  const [unSelectedAssignUsers, setUnSelectedAssignUsers] = useState([]);
  const [fileAttIdx, setFileAttIdx] = useState(undefined);
  const [filename, setFilename] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "file-attachment-popper" : undefined;

  //^ Formik =================================================================

  const replySchema = Yup.object().shape({
    reply: Yup.string().max(1000, "Reply must be at most 1000 characters long").required(),
  });
  const privateChatSchema = Yup.object().shape({
    privateText: Yup.string().max(1000, "Private reply much be at most 1000 characters long.").required(),
  });

  const replyFormik = useFormik({
    initialValues: {
      reply: "",
    },
    validationSchema: replySchema,
    onSubmit: (values) => {
      replyTicketMutate({
        ticketId: ticketData?.ticket?.ticket_unique_id,
        reply: values.reply,
        chatType: "1",
        files: replyFiles.replyChat,
      });
    },
  });

  const privateChatFormik = useFormik({
    initialValues: {
      privateText: "",
    },
    validationSchema: privateChatSchema,
    onSubmit: (values) => {
      if (userRole === 2 || userRole === 6) {
        privateReplyTicketMutate({
          ticketId: ticketData?.ticket?.ticket_unique_id,
          reply: values.privateText,
          chatType: "2",
          files: replyFiles.privateChat,
        });
      }
    },
  });

  //^ Formik =================================================================

  //^ download attachment mutation query
  const {
    isPending: downTicketAttachmentIsPending,
    isError: downTicketAttachmentIsError,
    error: downTicketAttachmentError,
    mutate: downTicketAttachmentMutate,
    reset: downTicketAttachmentReset,
  } = useMutation({
    mutationKey: ["download-ticket-attachment"],
    mutationFn: downloadTicketAttachment,
    onSuccess: (blob) => {
      downloadBlob(blob, filename);

      downTicketAttachmentReset();
    },
  });

  useEffect(() => {
    if (downTicketAttachmentIsError) {
      console.log(downTicketAttachmentError);
    }
  }, [downTicketAttachmentIsError, downTicketAttachmentError]);

  const fileUploads = ticketData?.ticket.file_upload ? JSON.parse(ticketData?.ticket.file_upload) : [];

  const fileDetails = fileUploads.map((filePath) => {
    const fileName = filePath.split("/").pop();
    const [filename, extension] = fileName.split(".");
    const icon = getFileIcon(extension.toLowerCase());
    return { filename, extension, icon, path: filePath };
  });

  //^ ref
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    const scrollHeight = messagesEndRef.current.scrollHeight;
    const height = messagesEndRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  const resizeFunc = () => {
    if (window.innerWidth > 1540) {
      setSideBar(true);
    } else {
      setSideBar(false);
    }
  };

  useEffect(() => {
    resizeFunc();
    window.addEventListener("resize", resizeFunc);
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  const chatBodyClass = classNames({
    "nk-msg-body": true,
    "bg-white": true,
    "show-message": mobileView,
    "profile-shown": false,
  });

  const toggleSidebar = () => {
    setSideBar(!sidebar);
  };

  const toggleAssignModal = () => {
    setAssignModal(!assignModal);
    setSearchNm("");
    // setSelectedAssignUsers([]);
  };

  //^ reply ticket mutation query
  const {
    isPending: replyTicketIsPending,
    isError: replyTicketIsError,
    error: replyTicketError,
    mutate: replyTicketMutate,
    reset: replyTicketReset,
  } = useMutation({
    mutationKey: ["reply-ticket"],
    mutationFn: replayTicketHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.status) {
          toast.success(data.message);
          ticketRefetch();
          scrollToBottom();
        } else {
          toast.error(data.message);
        }
        replyTicketReset();
      }
    },
  });

  //^ reply private ticket mutation query
  const {
    isPending: privateReplyTicketIsPending,
    isError: privateReplyTicketIsError,
    error: privateReplyTicketError,
    mutate: privateReplyTicketMutate,
    reset: privateReplyTicketReset,
  } = useMutation({
    mutationKey: ["private-reply-ticket"],
    mutationFn: replayTicketHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.status) {
          toast.success(data.message);
          ticketRefetch();
          scrollToBottom();
        } else {
          toast.error(data.message);
        }
        privateReplyTicketReset();
      }
    },
  });

  //^ close ticket mutation query
  const {
    isPending: closeTicketIsPending,
    isError: closeTicketIsError,
    error: closeTicketError,
    mutate: closeTicketMutate,
    reset: closeTicketReset,
  } = useMutation({
    mutationKey: ["close-ticket"],
    mutationFn: changeStatusTicketHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.status) {
          toast.success(data.message);
          ticketRefetch();
          queryClient.invalidateQueries([
            "get-all-tickets",
            "searchText",
            "selectedTab",
            "type",
            "status",
            "limit",
            "offset",
          ]);
        } else {
          toast.error(data.message);
        }
      }
      closeTicketReset();
    },
  });

  useEffect(() => {
    if (privateReplyTicketIsError) {
      console.log(privateReplyTicketError?.info);
    }
  }, [privateReplyTicketIsError, privateReplyTicketError]);

  useEffect(() => {
    if (replyTicketIsError) {
      console.log(replyTicketError?.info);
    }
  }, [replyTicketIsError, replyTicketError]);

  useEffect(() => {
    if (closeTicketIsError) {
      console.log(closeTicketError?.info);
    }
  }, [closeTicketIsError, closeTicketError]);

  function closeTicketHandler() {
    closeTicketMutate({ status: "1", ticket_id: ticketData?.ticket.ticket_unique_id, type: "status" });
  }

  const downloadAttachments = (fileString, index, filename) => {
    downTicketAttachmentMutate({
      file_path: fileString,
      ticketId: ticketData?.ticket.id,
      type: "ticket",
    });
    setFileAttIdx(index);
    setFilename(filename);
  };

  //^ get all assign user's query fn
  const {
    data: getAssignUsersData,
    isLoading: getAssignUsersIsLoading,
    isRefetching: getAssignUsersIsRefetching,
    isError: getAssignUsersIsError,
    error: getAssignUsersError,
    refetch: getAssignUsersRefetch,
  } = useQuery({
    queryKey: ["get-all-assign-users", searchNm, ticketData?.ticket.ticket_unique_id],
    queryFn: async () =>
      getAllAssignUsersHandler({
        limit: 5,
        offset: 0,
        search: searchNm,
        ticket_id: ticketData?.ticket.ticket_unique_id,
      }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getAssignUsersIsLoading || !getAssignUsersIsRefetching) {
      if (getAssignUsersData?.status) {
        // eslint-disable-next-line
        const assignedUsers = getAssignUsersData?.data?.tech_users.filter((user) => {
          if (user.isAssigned) {
            return user.id;
          }
        });

        const selectedAssignUsersArr =
          assignedUsers.length > 0 ? assignedUsers.map((assignUser) => assignUser?.id) : [];

        setSelectedAssignUsers(selectedAssignUsersArr);
      } else {
        if (getAssignUsersData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
        }
      }
    }

    // eslint-disable-next-line
  }, [getAssignUsersIsLoading, getAssignUsersIsRefetching, getAssignUsersData, dispatch]);

  useEffect(() => {
    if (getAssignUsersIsError) {
      console.log(getAssignUsersError?.info);
    }
  }, [getAssignUsersIsError, getAssignUsersError]);

  //^ assign user mutation query
  const {
    isPending: assignTicketToUserIsPending,
    isError: assignTicketToUserIsError,
    error: assignTicketToUserError,
    mutate: assignTicketToUserMutate,
    reset: assignTicketToUserReset,
  } = useMutation({
    mutationKey: ["assign-ticket-to-user"],
    mutationFn: assignUserHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          toggleAssignModal();
          toast.success(data.message);
          queryClient.invalidateQueries(["get-all-ticket-assign-users", "props.item.ticket_unique_id"]);
        } else {
          toast.error(data.message);
        }
      }
      assignTicketToUserReset();
    },
  });

  useEffect(() => {
    if (assignTicketToUserIsError) {
      console.log(assignTicketToUserError?.info);
    }
  }, [assignTicketToUserIsError, assignTicketToUserError]);

  const onInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;

    setSearchNm(value);
  };

  const handleMenuItemClick = (e, id) => {
    e.preventDefault();

    setSelectedAssignUsers((prevSelectedItems) => {
      const isAlreadyAssigned = getAssignUsersData?.data?.tech_users.some((user) => user.id === id && user.isAssigned);

      if (prevSelectedItems.includes(id)) {
        //^ check if the unselected item is already assigned from the backend.

        if (isAlreadyAssigned) {
          setUnSelectedAssignUsers((prevUnSelectedItems) => {
            if (prevUnSelectedItems.includes(id)) {
              return prevUnSelectedItems.filter((itemId) => itemId !== id);
            } else {
              return [...prevUnSelectedItems, id];
            }
          });
        }

        return prevSelectedItems.filter((itemId) => itemId !== id);
      } else {
        if (isAlreadyAssigned) {
          setUnSelectedAssignUsers((prevUnSelectedItems) => {
            if (prevUnSelectedItems.includes(id)) {
              return prevUnSelectedItems.filter((itemId) => itemId !== id);
            } else {
              return [...prevUnSelectedItems, id];
            }
          });
        }

        return [...prevSelectedItems, id];
      }
    });
  };

  const handleAssignUser = () => {
    if (selectedAssignUsers.length === 0) {
      toast.error("Please select atleast one user");
      return;
    }

    if (unSelectedAssignUsers?.length > 0) {
      assignTicketToUserMutate({
        ticket_id: ticketData?.ticket?.ticket_unique_id,
        action: "remove",
        user_id: unSelectedAssignUsers,
      });
    }

    assignTicketToUserMutate({
      ticket_id: ticketData?.ticket?.ticket_unique_id,
      action: "assign",
      user_id: selectedAssignUsers,
    });
  };

  //^ files states ===================================================================
  const handleReplyFileChange = (inputFileName, newFiles) => {
    setReplyFile((prevFiles) => ({
      ...prevFiles,
      [inputFileName]: newFiles,
    }));
  };

  const handleReplyRemoveFile = (inputFileName, index) => {
    setReplyFile((prevFiles) => ({
      ...prevFiles,
      [inputFileName]: prevFiles[inputFileName].filter((_, i) => i !== index),
    }));
  };

  //^ files states ===================================================================

  return (
    <>
      {assignTicketToUserIsError && (
        <ErrorAlert
          title={`${assignTicketToUserError?.status || 500}`}
          description={assignTicketToUserError?.info?.message || "Something went wrong"}
          isConformed={() => assignTicketToUserReset()}
        />
      )}
      {getAssignUsersIsError && (
        <ErrorAlert
          title={`${getAssignUsersError?.status || 500}`}
          description={getAssignUsersError?.info?.message || "Something went wrong"}
          isConformed={() => getAssignUsersRefetch()}
        />
      )}
      {replyTicketIsError && (
        <ErrorAlert
          title={replyTicketError?.status}
          description={replyTicketError?.info.message}
          isConformed={() => replyTicketReset()}
        />
      )}
      {downTicketAttachmentIsError && (
        <ErrorAlert
          title={downTicketAttachmentError?.status}
          description={downTicketAttachmentError?.info.message}
          isConformed={() => downTicketAttachmentReset()}
        />
      )}
      {ticketData ? (
        <div className={chatBodyClass}>
          <div className="nk-msg-head">
            <div className="mb-2">
              <BreadCrumb />
            </div>
            <h4 className="title d-none d-lg-block">{ticketData?.ticket.title}</h4>
            <div className="nk-msg-head-meta">
              <div className="d-none d-lg-block">
                <ul className="nk-msg-tags">
                  <li>
                    <span className="label-tag">
                      <Icon name="flag-fill" /> <span>{ticketData?.ticket.category.title}</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="d-lg-none">
                <IconButton
                  className="btn-trigger nk-msg-hide ms-n1"
                  onClick={() => dispatch(ticketAction.setTicketMobileViewHandler(false))}
                >
                  <Icon name="arrow-left" />
                </IconButton>
              </div>
              <Stack alignItems={"center"} direction={"row"} className="nk-msg-actions" gap={"1.25rem"}>
                {ticketData?.ticket.status === "1" ? (
                  <Box>
                    <Chip
                      size="small"
                      label={
                        <>
                          <Stack direction={"row"} gap={1} alignItems={"center"} color={"white"} className="fw-bold">
                            <Stack alignItems={"center"}>
                              <Icon name="check" />
                            </Stack>
                            <Box>Closed</Box>
                          </Stack>
                        </>
                      }
                      color="error"
                    />
                  </Box>
                ) : (
                  <Box>
                    <Button variant="outlined" size="small" type="button" onClick={closeTicketHandler}>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Icon name="check" />
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <Box>Mark as Closed</Box>
                          {closeTicketIsPending ? (
                            <Stack alignItems={"center"}>
                              <CircularProgress size={15} />
                            </Stack>
                          ) : (
                            ""
                          )}
                        </Stack>
                      </Stack>
                    </Button>
                  </Box>
                )}
                {ticketData?.ticket.status === "1" ? (
                  ""
                ) : userRole === 2 || userRole === 6 ? (
                  <Box>
                    <Button
                      sx={{
                        display: "none",
                        "@media (min-width: 65.5rem)": {
                          display: ticketData?.ticket.status === "1" ? "none" : "inline-flex",
                        },
                      }}
                      size="small"
                      type="button"
                      variant="outlined"
                      startIcon={<GroupAddOutlinedIcon />}
                      onClick={() => {
                        toggleAssignModal();
                      }}
                    >
                      Assign Tech User
                    </Button>
                    <IconButton
                      color="primary"
                      sx={{
                        display: ticketData?.ticket.status === "1" ? "none" : "inline-flex",
                        "@media(min-width: 65.5rem)": {
                          display: "none",
                        },
                      }}
                      onClick={() => {
                        toggleAssignModal();
                      }}
                    >
                      <GroupAddOutlinedIcon />
                    </IconButton>
                  </Box>
                ) : (
                  ""
                )}
              </Stack>
            </div>
            <a
              href="#toggle"
              onClick={(ev) => {
                ev.preventDefault();
                toggleSidebar();
              }}
              className={`d-none nk-msg-profile-toggle profile-toggle ${sidebar ? "active" : ""}`}
            >
              <Icon name="arrow-left" />
            </a>
          </div>
          {/*nk-msg-head*/}
          <SimpleBar className="nk-msg-reply nk-reply" scrollableNodeProps={{ ref: messagesEndRef }}>
            <div className="nk-msg-head py-4 d-lg-none">
              <h4 className="title">{ticketData?.ticket.title}</h4>
              <ul className="nk-msg-tags">
                <li>
                  <span className="label-tag">
                    <Icon name="flag-fill" /> <span>Technical Problem</span>
                  </span>
                </li>
              </ul>
            </div>
            <div className="nk-reply-item">
              <div className="nk-reply-header">
                <div className="user-card">
                  <Avatar sx={{ width: 40, height: 40, bgcolor: red[500] }} alt={ticketData?.ticket.user.username}>
                    <Typography fontWeight={500} variant="subtitle1" color={"white"}>
                      {getInitials(ticketData?.ticket.user.username || "")}
                    </Typography>
                  </Avatar>
                  <div className="user-name">{ticketData?.ticket.user.username}</div>
                </div>
                <Stack className="date-time">
                  <span>{moment(ticketData?.ticket.created_at).format("DD MMM")}</span>
                  <span>{moment(ticketData?.ticket.created_at).format("h:mm A")}</span>
                </Stack>
              </div>
              <div className="nk-reply-body">
                <div className="nk-reply-entry entry">
                  <Typography variant="subtitle1">{ticketData?.ticket.description}</Typography>
                </div>
              </div>
              {fileUploads.length > 0 ? (
                <div className="attach-files">
                  <div className="d-flex align-items-center" style={{ gap: "0.625rem" }}>
                    <ul className="attach-list">
                      {fileDetails.map((att, index) => (
                        <li className="attach-item" key={index}>
                          <Box className="download" width={"100%"}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"} c
                            >
                              <Stack direction={"row"} gap={"0.425rem"} alignItems={"center"}>
                                <AttachFileIcon color="primary" sx={{ fontSize: "1.4rem" }} />
                                <span>{att.filename}</span>
                              </Stack>
                              <IconButton
                                sx={{
                                  "&:hover": {
                                    color: styleVar.errorMain,
                                  },
                                }}
                                onClick={() => downloadAttachments(att.path, index, att.filename || "")}
                                disabled={fileAttIdx === index && downTicketAttachmentIsPending}
                              >
                                {fileAttIdx === index && downTicketAttachmentIsPending ? (
                                  <CircularProgress size={24} color="primary" />
                                ) : (
                                  <FileDownloadOutlinedIcon />
                                )}
                              </IconButton>
                            </Stack>
                          </Box>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="attach-foot">
                    <span className="attach-info">{fileUploads.length} files attached</span>
                    <a
                      className="attach-download link d-none"
                      href="#item"
                      onClick={(ev) => {
                        ev.preventDefault();
                        downloadAttachments("");
                      }}
                    >
                      <Icon name="download"></Icon>
                      <span>Download All</span>
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {replyFilter.map((reply, index) => {
              return (
                <React.Fragment key={index}>
                  <Divider>{moment(reply.date).format("DD MMM")}</Divider>
                  {reply?.replies
                    ? reply?.replies.length > 0 &&
                    reply?.replies.map((replyItem, index) => {
                      return (
                        <React.Fragment key={index}>
                          <ReplyItem
                            ticketId={ticketData?.ticket?.ticket_unique_id}
                            userRole={userRole}
                            item={replyItem}
                          />
                        </React.Fragment>
                      );
                    })
                    : ""}
                </React.Fragment>
              );
            })}

            {ticketData?.ticket.status === "0" ? (
              <Card variant="outlined" className={`nk-reply-form rounded-4 ${styles["reply-tab"]}`}>
                <div className="nk-reply-form-header">
                  <ul className="nav nav-tabs-s2 nav-tabs nav-tabs-sm">
                    {ticketTabsItem.map((tabItem, index) => {
                      if ((userRole === 2 || userRole === 6) && tabItem.value === "private notes") {
                        return (
                          <li key={index} className="nav-item">
                            <a
                              href="#tab"
                              className={`nav-link ${activeTab === tabItem.id.toString() ? "active" : ""}`}
                              onClick={(ev) => {
                                ev.preventDefault();
                                setActiveTab(tabItem.id.toString());
                              }}
                            >
                              {tabItem.title}
                            </a>
                          </li>
                        );
                      } else if (tabItem.value === "reply") {
                        return (
                          <li key={index} className="nav-item">
                            <a
                              href="#tab"
                              className={`nav-link ${activeTab === tabItem.id.toString() ? "active" : ""}`}
                              onClick={(ev) => {
                                ev.preventDefault();
                                setActiveTab(tabItem.id.toString());
                              }}
                            >
                              {tabItem.title}
                            </a>
                          </li>
                        );
                      }

                      return <></>;
                    })}
                  </ul>
                  <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
                    <Stack direction={"row"} gap={"0.625rem"} className="nk-reply-form-title">
                      <Typography variant="subtitle1">Reply as:</Typography>
                      <Avatar sx={{ width: 30, height: 30, bgcolor: red[500] }} alt={getCookie("username", "")}>
                        <Typography fontWeight={500} variant="subtitle2" color={"white"}>
                          {getInitials(getCookie("username", ""))}
                        </Typography>
                      </Avatar>
                    </Stack>
                  </Stack>
                </div>
                <div className="tab-content">
                  <div className={`tab-pane ${activeTab === "1" ? "active" : ""}`}>
                    <div className="nk-reply-form-editor">
                      <form onSubmit={replyFormik.handleSubmit}>
                        <Stack>
                          <Stack>
                            <Stack gap={"0.325rem"} className="p-4">
                              <textarea
                                name="reply"
                                onChange={replyFormik.handleChange}
                                onBlur={replyFormik.handleBlur}
                                placeholder="Reply here..."
                                className="form-control"
                              />
                              {replyFormik.errors.reply && replyFormik.touched.reply ? (
                                <Typography fontWeight={600} className="invalid">
                                  {replyFormik.errors.reply}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Stack>
                            <Stack className="px-4" gap={"0.625rem"}>
                              <Stack direction={"row"} gap={"0.625rem"} alignItems={"center"}>
                                <Typography>Upload a file attachment (you can attach at most 3 files)</Typography>
                                <Box>
                                  <IconButton
                                    aria-describedby={id}
                                    variant="contained"
                                    onClick={(event) => {
                                      handleClick(event);
                                      replyInputRef.current.click();
                                    }}
                                  >
                                    <AttachFileIcon sx={{ rotate: "40deg" }} />
                                  </IconButton>
                                </Box>
                              </Stack>
                              <FileUpload
                                labelName="Upload a file attachment (you can attach at most 3 files)"
                                inputFileName="replyChat"
                                files={replyFiles.replyChat}
                                onFileChange={handleReplyFileChange}
                                onFileRemove={handleReplyRemoveFile}
                                fileCardStyle={{
                                  width: "100%",
                                  "@media (min-width: 65.5rem)": {
                                    width: "50%",
                                  },
                                }}
                                showInput={false}
                                inputRef={replyInputRef}
                              />
                            </Stack>
                          </Stack>
                          <Divider />
                          <Box className="p-4">
                            <Button
                              variant="contained"
                              disabled={replyTicketIsPending || !replyFormik.isValid}
                              type="submit"
                              style={{ gap: "0.625rem" }}
                              startIcon={
                                replyTicketIsPending ? (
                                  <CircularProgress size={18} style={{ color: "inherit", borderWidth: "2px" }} />
                                ) : (
                                  ""
                                )
                              }
                            >
                              Reply
                            </Button>
                          </Box>
                        </Stack>
                      </form>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === "2" ? "active" : ""}`}>
                    <div className="nk-reply-form-editor">
                      <form onSubmit={privateChatFormik.handleSubmit}>
                        <Stack>
                          <Stack>
                            <Stack gap={"0.325rem"} className="p-4">
                              <textarea
                                name="privateText"
                                onChange={privateChatFormik.handleChange}
                                onBlur={privateChatFormik.handleBlur}
                                placeholder="Private chat here..."
                                className="form-control"
                              />
                              {privateChatFormik.errors.privateText && privateChatFormik.touched.privateText ? (
                                <Typography fontWeight={600} className="invalid">
                                  {privateChatFormik.errors.privateText}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Stack>
                            <Stack className="px-4" gap={"0.625rem"}>
                              <Stack direction={"row"} gap={"0.625rem"} alignItems={"center"}>
                                <Typography>Upload a file attachment (you can attach at most 3 files)</Typography>
                                <Box>
                                  <IconButton
                                    aria-describedby={id}
                                    variant="contained"
                                    onClick={(event) => {
                                      handleClick(event);
                                      privateInputRef.current.click();
                                    }}
                                  >
                                    <AttachFileIcon sx={{ rotate: "40deg" }} />
                                  </IconButton>
                                </Box>
                              </Stack>
                              <FileUpload
                                labelName="Upload a file attachment (you can attach at most 3 files)"
                                inputFileName="privateChat"
                                files={replyFiles.privateChat}
                                onFileChange={handleReplyFileChange}
                                onFileRemove={handleReplyRemoveFile}
                                fileCardStyle={{
                                  width: "100%",
                                  "@media (min-width: 65.5rem)": {
                                    width: "50%",
                                  },
                                }}
                                showInput={false}
                                inputRef={privateInputRef}
                              />
                            </Stack>
                          </Stack>
                          <Divider />
                          <Box className="p-4">
                            <Button
                              type="submit"
                              variant="contained"
                              startIcon={
                                privateReplyTicketIsPending ? <CircularProgress size={17} color="inherit" /> : ""
                              }
                              disabled={privateReplyTicketIsPending || !privateChatFormik.isValid}
                            >
                              Reply
                            </Button>
                          </Box>
                        </Stack>
                      </form>
                    </div>
                  </div>
                </div>
              </Card>
            ) : (
              ""
            )}
          </SimpleBar>

          <div className="d-none">
            <MessageProfileSidebar
              sidebar={sidebar}
              profile={{
                name: ticketData?.ticket.user.username,
                closed: ticketData?.ticket.status === "1" ? true : false,
              }}
            />
          </div>

          {sidebar && (
            <div className={window.innerWidth < 1550 ? "nk-msg-profile-overlay" : ""} onClick={() => toggleSidebar()} />
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="fs-4 fw-bold text-danger">
            <EmptyTicket />
          </div>
        </div>
      )}

      {/*Assign Members Modal*/}
      <Dialog
        open={assignModal}
        onClose={toggleAssignModal}
        PaperProps={{
          component: "form",
          sx: {
            width: "100%",
            maxWidth: "37.5rem",
          },
          onSubmit: (event) => {
            event.preventDefault();
            toggleAssignModal();
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h3">Assign Users</Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={toggleAssignModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack gap={2}>
            <Stack gap={2}>
              <Box>
                <div className="p-0 px-1">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-left">
                        <Icon name="search" />
                      </div>
                      <Input
                        type="text"
                        className="form-control bg-transparent border border-2"
                        placeholder="Search by name"
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </Box>

              <div>
                {getAssignUsersIsLoading || getAssignUsersIsRefetching ? (
                  <AssignUserSkeleton />
                ) : getAssignUsersData?.status ? (
                  <ul className="link-list-plain">
                    {getAssignUsersData?.data?.tech_users.map((user, index) => (
                      <MenuList component={"li"} key={index}>
                        <MenuItem
                          sx={{ padding: "0.6rem 1rem", borderRadius: "0.625rem" }}
                          onClick={(e) => handleMenuItemClick(e, user.id)}
                          selected={selectedAssignUsers.includes(user.id)}
                        >
                          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                            <UserAvatar text={getInitials(user.username || "")} theme={user.theme} className="sm" />
                            <div>
                              <Typography textTransform={"capitalize"}>{user.username}</Typography>
                            </div>
                          </Stack>
                          {selectedAssignUsers.includes(user.id) && <CloseIcon className="ms-auto" />}
                        </MenuItem>
                      </MenuList>
                    ))}
                  </ul>
                ) : (
                  <Box className="d-flex justify-content-center align-items-center w-100">
                    <Typography className="fs-4 fw-bold text-danger" textTransform={"capitalize"}>
                      {getAssignUsersData?.message}
                    </Typography>
                  </Box>
                )}
              </div>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: theme.spacing(2) }}>
          <Stack justifyContent={"center"} alignItems={"flex-end"}>
            <Button
              type="button"
              onClick={handleAssignUser}
              variant="contained"
              disabled={selectedAssignUsers.length === 0 && unSelectedAssignUsers.length === 0}
              startIcon={<PersonAddAltIcon />}
              endIcon={
                assignTicketToUserIsPending ? (
                  <Stack alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress sx={{ color: "white" }} size={18} />
                  </Stack>
                ) : (
                  ""
                )
              }
            >
              Assign Users
            </Button>
          </Stack>
          <Button variant="contained" type="button" color="secondary" onClick={toggleAssignModal}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
