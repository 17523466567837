import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

//^ utils
import { useTheme } from "../provider/Theme";

// import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import AppDropdown from "./dropdown/app/App";
import { Icon } from "../../components/Component";

import TicketContext from "../../components/context/TicketList";

//^ mui
import { AddToPhotos as AddToPhotosIcon } from "@mui/icons-material";
import { Stack, Button } from "@mui/material";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import "../../css/common.css";
import supportA from "../../images/applogos/Support.png";
import supportB from "../../images/applogos/Support B.png";
const AppHeader = ({ fixed, className, app, isMobile }) => {
  const theme = useTheme();
  const location = useLocation();

  const pathname = location.pathname;

  // eslint-disable-next-line
  const { headCreateTicket } = useContext(TicketContext);

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  const [fullScreenOn, setFullScreenOn] = useState(false);
  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${date}/${month}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className={`container-fluid ${headerClass} section-header header-border`}>
      <div className="nk-header-wrap">
        {/* <div className="nk-header-app-name">
        </div> */}
        <div className="col-4 col-lg-4">
          <div className="align-items-center">
            <div className="nk-header-logo px-2">
              <img src={theme.skin === "dark" ? supportB : supportA}
                alt="" style={{ height: "40px" }} />
            </div>
            {/* <div className="d-flex flex-column">
              <span className="fs-5 pb-0">Support</span>
              <span style={{ fontSize: "12px", marginTop: "-5px" }} className=" text-secondary">by SiloCloud</span>
            </div> */}
          </div>
        </div>
        <div className="col-3 ms-auto d-none d-md-block ">
          <div className="d-flex flex-row">
            <div className="lh-17px pe-3">
              <p className="mb-0 fw-bold text-blue">{time}</p>
              <p className="fs-9px mb-0">{date}</p>
            </div>
            <div>
              <a href="https://calendar.silocloud.io/">
                <Icon name="calender-date" className="head-icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="nk-header-tools">
          <ul className="nk-quick-nav">
            <Stack direction={"row"} alignItems={"center"} className="icon-gap">
              {headCreateTicket && (
                <li className="d-none d-lg-block notification-dropdown me-n1">
                  {pathname === "/create-ticket" ? (
                    <Link to={`${"/ticket"}`}>
                      <Button variant="contained">
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                          <ConfirmationNumberIcon />
                          {isMobile ? "+Ticket" : "Show Tickets"}
                        </Stack>
                      </Button>
                    </Link>
                  ) : (
                    <Link to={`${"/create-ticket"}`}>
                      <Button variant="contained">
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                          <AddToPhotosIcon fontSize="small" />
                          {isMobile ? "+Ticket" : "Create ticket"}
                        </Stack>
                      </Button>
                    </Link>
                  )}
                </li>
              )}

              <li className="mt-1 full-screen">
                {fullScreenOn ? (
                  <Icon name="minimize" className="pointer fs-4" onClick={toggleFullScreen} />
                ) : (
                  <Icon name="maximize" className="pointer fs-4" onClick={toggleFullScreen} />
                )}
              </li>
              <li >
                <Notification />
              </li>
              <li >
                <AppDropdown />
              </li>
              <li className="user-dropdown d-flex me-2">
                <User />
              </li>
            </Stack>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AppHeader;
