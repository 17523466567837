import React from "react";

import { Box, Stack, Skeleton, MenuList, MenuItem } from "@mui/material";

const NotificationSkeletonLoader = () => {
  return (
    <ul>
      {Array.from({ length: 10 }).map((_, index) => {
        return (
          <MenuList component={"li"}>
            <MenuItem sx={{ padding: "0.6rem 1rem" }}>
              <Stack justifyContent={"space-between"} alignItems={"flex-start"} direction={"row"} width={"100%"}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Box>
                    <Skeleton variant="circular" animation="wave" sx={{ width: "2.5rem", height: "2.5rem" }} />
                  </Box>
                  <Stack className="nk-notification-content">
                    <Skeleton variant="text" animation="wave" sx={{ width: "10rem" }} />
                    <Skeleton variant="text" animation="wave" sx={{ width: "4rem" }} />
                  </Stack>
                </Stack>
                <Stack justifyContent={"flex-start"}>
                  <Skeleton variant="text" animation="wave" width={"1.5rem"} />
                </Stack>
              </Stack>
            </MenuItem>
          </MenuList>
        );
      })}
    </ul>
  );
};

export default NotificationSkeletonLoader;
