import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mobileView: false,
  showRedirectLogin: false,
};

const ticketSlice = createSlice({
  initialState,
  name: "ticket",
  reducers: {
    setTicketMobileViewHandler: (state, { payload }) => {
      state.mobileView = payload;
    },
    setShowRedirectLoginHandler: (state, { payload }) => {
      state.showRedirectLogin = payload;
    },
  },
});

export const ticketAction = ticketSlice.actions;

const ticketReducer = ticketSlice.reducer;
export default ticketReducer;
