import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export default function BreadCrumb() {
  const location = useLocation();

  let currentLink = "";

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index) => {
      currentLink += `/${crumb}`;

      const capitalizeCrumb = crumb.charAt(0).toUpperCase() + crumb.slice(1).toLowerCase();

      return (
        <BreadcrumbItem key={index}>
          <NavLink to={currentLink}>{capitalizeCrumb}</NavLink>
        </BreadcrumbItem>
      );
    });

  return (
    <Breadcrumb className="breadcrumb-arrow">
      <BreadcrumbItem>
        <Link to={"/"}>Home</Link>
      </BreadcrumbItem>
      {crumbs}
    </Breadcrumb>
  );
}
