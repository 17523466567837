import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

//^ http request
import { getAllTicketHandler } from "../../../../http/get-apis";

//^ redux actions
import { ticketAction } from "../../../../store/slices/ticket-slice";

import { pagePerUserData } from "../../charts/default/DefaultData";
import { DataTableRow, DataTableHead, DataTableItem } from "../../../table/DataTable";
import RecentTicketSkeleton from "../../../ui/loading/Skeleton/RecentTicketSkeleton";
import ErrorAlert from "../../../ui/model/error-model/ErrorModelV2";
import Link from "../../../ui/links/Link";
import EmptyTicket from "../../../svg/empty-ticket";

const PageViewer = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState(pagePerUserData);

  const {
    data: initialTicketsData,
    isLoading: initialTicketsIsLoading,
    isRefetching: initialTicketsIsRefetching,
    isError: initialTicketsIsError,
    error: initialTicketsError,
    refetch: initialTicketsRefetch,
  } = useQuery({
    queryKey: ["get-initial-tickets"],
    queryFn: async () => getAllTicketHandler({ limit: 5, type: "all" }),
  });

  useEffect(() => {
    if (!initialTicketsIsRefetching || !initialTicketsIsLoading) {
      if (initialTicketsData && initialTicketsData?.status) {
        const filteredTicketData = initialTicketsData?.data?.tickets.map((ticket) => ({
          id: ticket?.id,
          uniqueId: ticket?.ticket_unique_id,
          ticketName: ticket?.title,
          status: ticket?.status === "0" ? "open" : "closed",
        }));

        setData(filteredTicketData);
      } else {
        if (initialTicketsData?.redirect) {
          dispatch(ticketAction.setShowRedirectLoginHandler(true));
          window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
        } else if (initialTicketsData?.toast && initialTicketsData?.message !== "No tickets found") {
          toast.error(initialTicketsData?.message);
        }
      }
    }

    // eslint-disable-next-line
  }, [initialTicketsData, initialTicketsIsLoading, initialTicketsIsRefetching]);

  useEffect(() => {
    if (initialTicketsIsError) {
      console.log(initialTicketsError?.info);
    }
  }, [initialTicketsIsError, initialTicketsError]);

  return (
    <React.Fragment>
      {initialTicketsIsError && (
        <ErrorAlert
          title={`${initialTicketsError?.status}` || "500"}
          description={initialTicketsError?.info?.message}
          isConformed={() => initialTicketsRefetch()}
        />
      )}
      <div className="card-inner">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">Recent Tickets</h6>
          </div>
        </div>
      </div>
      <div
        className={`${
          !initialTicketsData?.status && initialTicketsData?.message === "No tickets found" ? "h-100" : ""
        } nk-tb-list is-compact`}
      >
        {initialTicketsIsLoading ? (
          <RecentTicketSkeleton />
        ) : initialTicketsData?.status ? (
          <React.Fragment>
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span>Tickets</span>
              </DataTableRow>
              <DataTableRow className="text-end">
                <span>Status</span>
              </DataTableRow>
            </DataTableHead>
            {data.map((item) => {
              return (
                <DataTableItem key={item.id}>
                  <DataTableRow>
                    <span className="tb-sub">
                      <Link href={`/ticket/${item.uniqueId}`}>{item.ticketName}</Link>
                    </span>
                  </DataTableRow>
                  <DataTableRow className="text-end">
                    <span className="tb-sub tb-amount">
                      <span
                        className={`${item.status === "open" ? "text-success" : "text-danger"} fw-semibold`}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.status}
                      </span>
                    </span>
                  </DataTableRow>
                </DataTableItem>
              );
            })}
          </React.Fragment>
        ) : initialTicketsData?.message === "No tickets found" ? (
          <div className="p-4 d-flex justify-content-center align-items-center h-100">
            <div className="fw-bold fs-6 text-danger">
              <EmptyTicket />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};
export default PageViewer;
