import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";

//^ mui
import debounce from "lodash/debounce";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

//^ stylesheets
import classes from "../../../CreateTicket.module.scss";
import SimpleBar from "simplebar-react";
import styles from "../../../../assets/scss/_themes-vars.module.scss";

export default function TitleData({ isLoading, TitleData, formik, titleMutate, titleReset, titleIsSuccess }) {
  const [titleData, setTitleData] = useState(TitleData ? TitleData : []);

  useEffect(() => {
    if (titleIsSuccess && !isLoading) {
      setTitleData(TitleData);
    }
  }, [titleIsSuccess, TitleData, isLoading]);

  //^ Title State ====================================================================

  const debouncedOnSearchInputChange = debounce((ev) => {
    const title = ev.target.value;

    if (title.length === 0) {
      titleMutate({ categoryId: -1, searchValue: title });
      setTitleData([]);
    } else if (formik.values.categoryId !== 0) {
      titleMutate({ categoryId: formik.values.categoryId, searchValue: title });
    } else {
      titleReset();
    }
  }, 500);

  const titleChangeHandler = (event) => {
    debouncedOnSearchInputChange(event);
  };

  //^ Title State ===================================================================

  return (
    <>
      <div>
        <Label className="form-label" htmlFor="title">
          <Typography variant="subtitle1" component="span">
            Title
          </Typography>
          <Typography component="span" variant="h5" fontWeight={"900"} color={styles.primaryMain}>
            &nbsp;*
          </Typography>
        </Label>
        <input
          className="form-control"
          placeholder="Enter title"
          name="title"
          id={"title"}
          onChange={(event) => {
            formik.handleChange(event);
            titleChangeHandler(event);
          }}
          onBlur={formik.handleBlur}
          required
        />
        {formik.errors.title && formik.touched.title ? (
          <span id="title" className="invalid">
            {formik.errors.title}
          </span>
        ) : (
          ""
        )}
      </div>
      {titleIsSuccess ? (
        titleIsSuccess && isLoading ? (
          <Card elevation={4} sx={{ padding: "1.25rem", borderRadius: "0.625rem" }}>
            <SimpleBar className={`${classes["title-dropdown"]}`}>
              <Stack gap="0.6325rem">
                {Array.from({ length: 3 }).map((_, index) => (
                  <Stack gap="0.225rem" key={index}>
                    <Skeleton animation="wave" variant="text" height={"3.25rem"} width="50%" />
                    <Stack gap={"0.225rem"}>
                      <Skeleton animation="wave" variant="text" width="60%" />
                      <Skeleton animation="wave" variant="text" width="50%" />
                      <Skeleton animation="wave" variant="text" width="40%" />
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </SimpleBar>
          </Card>
        ) : (
          titleData && (
            <>
              <Card elevation={4} sx={{ padding: "1.25rem", borderRadius: "0.625rem" }}>
                <SimpleBar className={`${classes["title-dropdown"]}`}>
                  {titleData.length <= 0 ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <p>No results</p>
                    </div>
                  ) : (
                    titleData.map((title, index) => {
                      let quesNo = index;
                      quesNo++;

                      return (
                        <>
                          <div className="mt-2 d-flex flex-column" style={{ gap: "0.925rem", paddingBottom: "1rem" }}>
                            <div className={`${classes["title-accordion"]} px-2`}>
                              <div>
                                <p
                                  className={`text-primary d-flex ${classes["accordion-title"]}`}
                                  style={{ gap: "0.425rem" }}
                                >
                                  <span>{`${quesNo}.`}</span>
                                  <span>{title.title}</span>
                                </p>
                                <p className="px-4">{title.answer}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  )}
                </SimpleBar>
              </Card>
            </>
          )
        )
      ) : (
        ""
      )}
    </>
  );
}
