import { useSelector } from "react-redux";
import classNames from "classnames";
import Simplebar from "simplebar-react";

//^ mui
import { Box, Stack, Skeleton, Divider } from "@mui/material";

export default function TicketPageSkeleton() {
  //^ redux selectors
  const mobileView = useSelector((state) => state.ticket.mobileView);

  const chatBodyClass = classNames({
    "nk-msg-body": true,
    "bg-white": true,
    "show-message": mobileView,
    "profile-shown": false,
  });

  return (
    <div className={chatBodyClass}>
      <Simplebar className="w-100">
        <Stack gap={2} width={"100%"} overflow={"auto"} className="">
          <Stack width={"100%"} gap={3} padding={1} paddingTop={4} paddingLeft={4}>
            <Skeleton animation="wave" variant="rounded" width="30%" />

            <Stack justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
              <Box>
                <Skeleton animation="wave" variant="circular" width={"2.5rem"} height={"2.5rem"} />
              </Box>
              <Skeleton animation="wave" variant="rounded" height={"1.75rem"} width={"5rem"} />
            </Stack>
          </Stack>
          <Divider />
          <Stack width={"100%"} padding={1} paddingTop={4} paddingLeft={4}>
            <Skeleton animation="wave" variant="text" width={"20%"} />
            <Skeleton animation="wave" variant="text" width={"30%"} />
          </Stack>
          <Divider />
          <Stack padding={"1.25rem"}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"} gap={"1.25rem"}>
              <Stack width={"100%"} gap={5}>
                <Stack direction={"row"} alignItems={"start"} gap={2} width={"100%"}>
                  <Box>
                    <Skeleton animation="wave" variant="circular" width={"2.5rem"} height={"2.5rem"} />
                  </Box>
                  <Stack gap={3} width={"100%"}>
                    <Stack width={"100%"} gap={1}>
                      <Skeleton animation="wave" variant="text" width={"20%"} />
                      <Skeleton animation="wave" variant="rounded" height={"2.25rem"} width={"100%"} />
                    </Stack>
                    <Stack width={"100%"} gap={1}>
                      <Skeleton animation="wave" variant="rounded" height={"5.25rem"} width={"100%"} />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction={"row"} alignItems={"start"} gap={2} width={"100%"}>
                  <Box>
                    <Skeleton animation="wave" variant="circular" width={"2.5rem"} height={"2.5rem"} />
                  </Box>
                  <Stack gap={3} width={"100%"}>
                    <Stack width={"100%"} gap={1}>
                      <Skeleton animation="wave" variant="text" width={"20%"} />
                      <Skeleton animation="wave" variant="rounded" height={"2.25rem"} width={"100%"} />
                    </Stack>
                    <Stack width={"100%"} gap={1}>
                      <Skeleton animation="wave" variant="rounded" height={"5.25rem"} width={"100%"} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Skeleton animation="wave" variant="text" width={"15%"} />
            </Stack>
          </Stack>
          <Divider />
          <Stack
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            direction={"row"}
            paddingLeft={"1.25rem"}
            paddingRight={"1.25rem"}
          >
            <Box width={"100%"}>
              <Skeleton animation="wave" variant="text" width={"10%"} />
            </Box>
            <Stack direction={"row"} alignItems={"center"} gap={1} width={"20%"}>
              <Skeleton animation="wave" variant="text" width={"100%"} />
              <Box>
                <Skeleton animation="wave" variant="circular" width={"2.5rem"} height={"2.5rem"} />
              </Box>
            </Stack>
          </Stack>
          <Divider />
          <Box padding={"1.25rem"}>
            <Stack width={"100%"}>
              <Skeleton animation="wave" width={"95%"} />
              <Skeleton animation="wave" width={"80%"} />
              <Skeleton animation="wave" width={"70%"} />
              <Skeleton animation="wave" width={"50%"} />
            </Stack>
          </Box>
        </Stack>
      </Simplebar>
    </div>
  );
}
