import React from "react";
import Head from "../../../../layout/head/Head";
import { LinearProgress, Stack } from "@mui/material";

export default function PageLoader() {
  return (
    <>
      <Head title={"Loading"} />
      <Stack className="w-100 vh-100" sx={{ zIndex: "3000", position: "fixed" }}>
        <LinearProgress />
      </Stack>
    </>
  );
}
