import { Stack, Box, Skeleton, Divider } from "@mui/material";

import { useTheme } from "../../../../layout/provider/Theme";

export default function RecentTicketSkeleton() {
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  return (
    <Box>
      <Box className="px-4 py-2" sx={{ width: "100%" }}>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"10rem"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
      </Box>
      <Divider color={isDark ? "white" : "black"} />
      <Stack gap={2} className="px-4 py-2" sx={{ width: "100%" }}>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
        <Stack gap={2} width={"100%"} direction={"row"} justifyContent={"space-between"}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"5rem"} />
        </Stack>
      </Stack>
    </Box>
  );
}
