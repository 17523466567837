import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";

//^ utils
import { getCookie } from "../../../../utils/Utils";

//^ components
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { appLinks } from "./user-list";
import AccountAvatar from "../../../../pages/pre-built/user-manage/AccountAvatar";
import { Stack } from "@mui/material";
import { postRequestData } from "../../../../utils/api-services";
import { Blockchain_icon, Blockchain_icon_dark } from "../../../../images/applogos";


const User = () => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  const appsUpdateTheme = async (theme) => {
    const Data = {
      "columnValue": theme === "dark" ? 1 : 2,
      "action": "update",
      "column": "theme",
      // "columnKey": key,
    };
    try {
      await postRequestData(Data, "public/frontend-settings");
      // console.log(response?.data?.frontend_settings?.theme, "updateTheme");
      // if (response.status) {
      //   themeUpdate.skin(response?.data?.frontend_settings?.theme === "1" ? "light" : "dark");
      // }
    } catch (error) {
      console.error("Error updating footer app visibility:", error);
    }
  };
  const linkItems = [
    {
      link: `https://silocloud.com/enumblockchain-explorer`,
      img: Blockchain_icon,
      imgDark: Blockchain_icon_dark,
      text: "BlockChain",
    },
    // {
    //   link: `https://silocloud.com/wallet-dashboard`,
    //   icon: "wallet",
    //   text: "Silo Wallet",
    // },
    // {
    //   link: `https://accounts.silocloud.io/`,
    //   icon: "setting-alt",
    //   text: "Account Settings",
    // },
  ];

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <AccountAvatar profileName={getCookie("username", "John")} />

        {/* <UserAvatar icon="user-alt" className="sm" /> */}
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <Stack direction={"row"} alignItems={"center"} gap={"1rem"}>
            <AccountAvatar profileName={getCookie("username", "John")} />
            <div className="user-info">
              <span className="lead-text">{getCookie("username", "John")}</span>
              <span className="sub-text">{getCookie("email", "john@mail.io")}</span>
            </div>
          </Stack>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* <LinkItem link="/user-profile-regular" icon="user-alt" onClick={toggle} className="d-none">
              View Profile
            </LinkItem> */}

            {linkItems.map((item, index) => (
              <li>
                <a key={index} href={item.link} icon={item.icon} onClick={toggle} className={` ${item.className}`}>
                  {item.img ? (
                    theme.skin === "light"
                      ? <img src={item.imgDark} alt={item.children} style={{ height: "15px" }} className="link-item-img" />
                      : <img src={item.img} alt={item.children} style={{ height: "15px" }} className="link-item-img" />
                  ) : (
                    <i className={`icon ni ${item.icon}`}></i>
                  )}
                  <span className="m-0 ms-2">{item.text}</span>
                </a>
              </li>
            ))}
            {/* <li>
              <a href={`https://silocloud.com/enumblockchain-explorer`} onClick={toggle}>
                <em class="icon ni ni-blockchain"></em>
                <span>BlockChain</span>
              </a>
            </li> */}
            <li>
              <a href={`https://silocloud.com/wallet-dashboard`} onClick={toggle}>
                <em class="icon ni ni-wallet"></em>
                <span>Silo Wallet</span>
              </a>
            </li>

            {appLinks.map((link, index) => {
              if (link.showLink) {
                return (
                  <React.Fragment key={index}>
                    <LinkItem link={link.link} icon={link.icon} onClick={toggle}>
                      {link.text}
                    </LinkItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    <LinkItem
                      linkClassName={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                      onClick={(ev) => {
                        ev.preventDefault();
                        appsUpdateTheme(theme.skin);
                        themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                      }}
                    >
                      {theme.skin === "dark" ? (
                        <>
                          <div className="d-flex align-items-center">
                            <Icon name={link.lightIcon} />
                            <span>{link.textLight}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex align-items-center">
                            <Icon name={link.darkIcon} />
                            <span>{link.textDark}</span>
                          </div>
                        </>
                      )}
                    </LinkItem>
                  </React.Fragment>
                );
              }
            })}

            {/* <LinkItem link="/user-profile-activity" icon="activity-alt" className="d-none" onClick={toggle}>
              Login Activity
            </LinkItem> */}
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href={`${process.env.REACT_APP_ACCOUNT_URL}logout`} className="ms-4">
              <Icon name="signout" className="ms-2" />
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
