import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import { useFormik } from "formik";
import { CardBody, Label, Spinner, Card, CardHeader, Form } from "reactstrap";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useNavigation } from "react-router";

//^ stylesheets
import classes from "./CreateTicket.module.scss";
import styles from "../assets/scss/_themes-vars.module.scss";

import Head from "../layout/head/Head";

//^ http request.
import { getCategoriesHandler, getTitleHandler } from "../http/get-apis";
import { createTicketHandler } from "../http/post-apis";

//^ lib
import { toggleErrorModel } from "../utils/Utils";

//^ mui component
import { Button } from "@mui/material";

//^ component
// import BreadCrumb from "./ui/bread-crumb/BreadCrumb";
import ErrorModel from "./ui/model/error-model/ErrorModel";
import { Icon, RSelect } from "./Component";
import ErrorAlert from "./ui/model/error-model/ErrorModelV2";
import TitleData from "./block/create-tickets/serach-title/SearchTitle";
import FileUpload from "./ui/attachments/FileUpload";
import LoginRedirectionMsg from "./ui/redirection-msg/RedirectionMsg";
import { Skeleton, Stack, Paper, Card as MuiCard, Container, CardActionArea, Typography, Divider } from "@mui/material";
import PageLoader from "./ui/loading/page-loader/PageLoader";
import useStorageUsage from "../utils/useStorageUsage";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Link } from "react-router-dom";

function CategoryContent({ formik, categories }) {
  // const categoryItemRef = useRef();

  return (
    <div className={`${classes.grid} ${classes["category-content-grid-control"]} ${classes["gap-4"]}`}>
      {categories.map((category, idx) => {
        const isCategoryIdEqual = parseInt(formik.values.categoryId) === category.value;

        return (
          <React.Fragment key={idx}>
            <MuiCard
              elevation={2}
              className={`rounded-5 h-100 ${isCategoryIdEqual ? "shadow-none" : ""}`}
              style={{
                cursor: isCategoryIdEqual ? "default" : "pointer",
                border: isCategoryIdEqual ? `2px solid ${styles.primaryMain}` : "",
              }}
              onClick={() => {
                formik.setFieldValue("categoryId", category.value);
              }}
            >
              <CardActionArea
                sx={{ height: "100%", minHeight: "2rem", cursor: isCategoryIdEqual ? "default" : "pointer" }}
              >
                <Card
                  style={{ background: "transparent" }}
                  className="h-100"
                  styles={{ cursor: isCategoryIdEqual ? "default" : "pointer" }}
                >
                  {/* <CardContent> */}
                  <CardHeader
                    style={{ padding: "1.2rem", borderTopLeftRadius: "0.625rem", borderTopRightRadius: "0.625rem" }}
                  >
                    <Stack>
                      <Stack width={"100%"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="h4">{category.name}</Typography>

                        {parseInt(formik.values.categoryId) === category.value ? (
                          <Icon name={"check-thick"} className={`fs-4 text-primary`} />
                        ) : (
                          ""
                        )}
                      </Stack>
                    </Stack>
                  </CardHeader>
                  <Divider />
                  <CardBody className="">
                    <Typography variant="subtitle1">{category.description}</Typography>
                  </CardBody>
                </Card>
                {/* </CardContent> */}
              </CardActionArea>
            </MuiCard>
            <div
              key={idx}
              className={`border p-1 px-2 ${classes["rounded-sm"]} ${classes["category-description"]} d-none`}
            >
              <div className={`${classes["flex"]} ${classes["gap-2"]} ${classes["items-center"]}`}>
                <div className="custom-control custom-control-sm custom-radio">
                  <input
                    // ref={categoryItemRef}
                    type="radio"
                    id={category.value}
                    name="categoryId"
                    onChange={formik.handleChange}
                    value={category.value}
                    className="custom-control-input"
                  />
                </div>
                <label htmlFor={category.value}>
                  <span className="fs-1 fw-semibold">{category.name}</span>
                  {category.description ? <p className="fs-6">{category.description}</p> : ""}
                </label>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default function CreateTicket() {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [categories, setCategories] = useState([]);

  const { storageDetails, loading } = useStorageUsage();
  const [formData, setFormData] = useState(null);
  // Storage filled alert logic
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (storageDetails.storageFilledAlert && formData) {
      createTicketMutate(formData);
    }
  };

  const isPageLoading = navigation.state === "loading";

  const formClass = classNames({
    "form-validate": true,
  });

  //^ get title mutation states
  const [getTitleIsSuccess, setGetTitleIsSuccess] = useState(false);
  const [getTitleData, setGetTitleData] = useState(undefined);

  //^ error states
  const [getCategoriesErrorModel, setGetCategoriesErrorModel] = useState(false);

  //^ other states
  const [files, setFiles] = useState({
    validId: [],
    socialsecurity: [],
    leaseagreement: [],
    mortgagestatement: [],
    utilitystatement: [],
    other: [],
    w2picture: [],
    D1099picture: [],
    ssabenefits: [],
    unemployementinc: [],
    selfemployement: [],
    collegedoc: [],
    otherformincome: [],
  });

  //^ FORMIK states ==================================================================
  const schema = Yup.object().shape({
    title: Yup.string().max(255, "Title must be at most 255 characters long").required("Title is required"),
    description: Yup.string().max(1000, "Description must be at most 1000 characters long"),
  });

  const initialValues = {
    categoryId: 0,
    tags: [],
    title: "",
    files: [],
    description: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("category_id", values.categoryId.toString());
      formData.append("description", values.description);
      formData.append("tags", JSON.stringify(values.tags));
      files.validId.forEach((file, index) => formData.append(`file_upload[${index}]`, file));

      setFormData(formData);
      if (storageDetails.storageFilledAlert) {
        setOpen(true); // Open the alert when storage is filled above 90%
      } else {
        createTicketMutate(formData);
      }

    },
    //eslint-disable-next-line
  });

  //^ FORMIK states ==================================================================

  //^ getting all of the categories data.
  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
    error: categoriesError,
    refetch: categoriesRefetch,
  } = useQuery({
    queryKey: ["get-categories"],
    queryFn: ({ signal }) => getCategoriesHandler({ signal }),
  });

  //^ getting tags based on the categories and search values mutation query.
  const {
    isPending: getTitlesIsPending,
    isError: getTitlesIsError,
    mutate: getTitlesMutate,
    reset: getTitlesReset,
  } = useMutation({
    mutationKey: ["get-category-based-titles"],
    mutationFn: getTitleHandler,
    onSuccess: (data) => {
      setGetTitleIsSuccess(true);
      setGetTitleData(data);
    },
  });

  useEffect(() => {
    if (!categoriesIsLoading && categoriesData?.status && categoriesData?.data?.support_categories) {
      const filteredCategories = categoriesData.data.support_categories.map((subCategory) => ({
        name: subCategory.title,
        description: subCategory.description,
        value: subCategory.id,
      }));

      formik.setFieldValue(
        "categoryId",
        categoriesData.data.support_categories.length > 0
          ? categoriesData.data.support_categories[0].id
          : formik.values.categoryId
      );

      setCategories(filteredCategories);
    } else if (categoriesData?.redirect) {
      window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}login`;
    }

    // eslint-disable-next-line
  }, [categoriesData, categoriesIsLoading]);

  useEffect(() => {
    if (categoriesIsError) {
      setGetCategoriesErrorModel(categoriesIsError);
    } else {
      setGetCategoriesErrorModel(categoriesIsError);
    }
  }, [categoriesIsError, categoriesError]);

  const options = [
    { value: "Feedback", label: "Feedback" },
    { value: "reCAPTCHA", label: "reCAPTCHA" },
    { value: "Request", label: "Request" },
  ];

  const animatedComponents = makeAnimated();

  //^ post create ticket mutation logic ==============================================

  const {
    isPending: createTicketIsPending,
    isError: createTicketIsError,
    error: createTicketError,
    mutate: createTicketMutate,
    reset: createTicketReset,
  } = useMutation({
    mutationKey: ["create-ticket"],
    mutationFn: createTicketHandler,
    onSuccess: (data) => {
      // if (data?.message === "Validation failed") {
      //   toast.error(data?.message);
      // }

      if (data.toast) {
        if (data.type === "error") {
          toast.error(
            <div className="toastr-text">
              <p>{data.message}</p>
            </div>
          );
        } else if (data.type === "success") {
          toast.success(
            <div className="toastr-text">
              <p>{data.message}</p>
            </div>
          );
          navigate("/ticket");
        } else if (data.message === "Validation failed") {
          toast.error(data?.message);
        } else if (data.type === "info") {
          toast.info(
            <div className="toastr-text">
              <p>{data.message}</p>
            </div>
          );
        }
      }
      createTicketReset();
    },
  });

  useEffect(() => {
    if (createTicketIsError) {
      console.log(createTicketError);
    }
  }, [createTicketError, createTicketIsError]);

  //^ post create ticket mutation logic ==============================================

  const handleTag = (selectOption) => {
    formik.setFieldValue("tags", selectOption);
  };

  //^ files states ===================================================================
  const handleFileChange = (inputFileName, newFiles) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [inputFileName]: newFiles,
    }));
  };

  const handleRemoveFile = (inputFileName, index) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [inputFileName]: prevFiles[inputFileName].filter((_, i) => i !== index),
    }));
  };

  //^ files states ===================================================================

  return (
    <>
      {isPageLoading ? <PageLoader /> : ""}

      {!loading && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <Typography style={{ color: "#fff" }}>
              Storage Filled Alert!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" color="#f4bd0e">
              Your storage has been filled with 90% or above. Please{' '}
              <Link
                to={storageDetails.upgradeLink}
                style={{ color: "#fff" }}
              >
                upgrade
              </Link>{' '}
              the plan.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {createTicketIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to create a new ticket.`}
          isConformed={() => createTicketReset()}
        />
      )}

      {getTitlesIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to get the title`}
          isConformed={() => getTitlesReset()}
        />
      )}

      <ErrorModel
        isOpen={getCategoriesErrorModel}
        toggleOpen={() => toggleErrorModel(setGetCategoriesErrorModel, getCategoriesErrorModel)}
        title={"Something Went Wrong"}
        description={"Unable to get categories."}
        onConfirmed={() => {
          toggleErrorModel(setGetCategoriesErrorModel, getCategoriesErrorModel);
          categoriesRefetch();
        }}
      />
      {categoriesData && !categoriesData?.status && categoriesData?.redirect ? (
        <LoginRedirectionMsg />
      ) : (
        <Container maxWidth="xl" className="p-0" >
          <Paper className={` p-3`}
            style={{ gap: "1rem", height: "calc(100vh - 115px)" }}>
            <div>
              {/* <BreadCrumb /> */}
            </div>
            <div>
              <Head title="Create Tickets" />
              <Form className={formClass} onSubmit={formik.handleSubmit}>
                <div className="d-flex flex-column" >
                  <div className={`d-flex flex-column ${classes["gap-2"]}`} >
                    <div className={`d-flex flex-column `}>
                      <Label className="form-label" htmlFor="categories">
                        Categories
                      </Label>
                      {categoriesIsLoading ? (
                        <Stack
                          gap={3}
                          sx={{
                            "@media (min-width: 65.5rem)": {
                              flexDirection: "row",
                            },
                          }}
                        >
                          <Skeleton
                            variant="rounded"
                            animation="wave"
                            width={"100%"}
                            height={"8rem"}
                            style={{ borderRadius: "0.625rem" }}
                          />
                          <Skeleton
                            variant="rounded"
                            animation="wave"
                            width={"100%"}
                            height={"8rem"}
                            style={{ borderRadius: "0.625rem" }}
                          />
                          <Skeleton
                            variant="rounded"
                            animation="wave"
                            width={"100%"}
                            height={"1rem"}
                            style={{ borderRadius: "0.625rem" }}
                          />
                          <Skeleton
                            variant="rounded"
                            animation="wave"
                            width={"100%"}
                            height={"1rem"}
                            style={{ borderRadius: "0.625rem" }}
                          />
                        </Stack>
                      ) : (
                        <CategoryContent formik={formik} categories={categories} />
                      )}
                    </div>
                    <div className={`row ${classes["gap-3"]}`}>
                      <div className="col-md-6">
                        <TitleData
                          setGetTitleData={setGetTitleData}
                          TitleData={
                            getTitleData?.type === "success" && getTitleData?.status
                              ? getTitleData?.data?.questions
                              : undefined
                          }
                          formik={formik}
                          isLoading={getTitlesIsPending}
                          titleMutate={getTitlesMutate}
                          titleReset={getTitlesReset}
                          titleIsSuccess={getTitleIsSuccess}
                        />
                        <Label className="form-label mt-3" htmlFor="tags">
                          Tags
                        </Label>
                        <RSelect
                          isMulti
                          components={animatedComponents}
                          options={options}
                          className="custom-rselect"
                          onChange={handleTag}
                        />
                      </div>
                      <Stack className="col-md-6">
                        <Label className="form-label" htmlFor="description">
                          Description
                          <Typography component="span" variant="h5" fontWeight={"900"} color={styles.primaryMain}>
                            &nbsp;*
                          </Typography>
                        </Label>
                        <Stack gap={"0.225rem"}>
                          <textarea
                            id={"description"}
                            onChange={(e) => formik.handleChange(e)}
                            onBlur={formik.handleBlur}
                            name="description"
                            className="form-control"
                            required
                          />
                          {formik.errors.description && formik.touched.description ? (
                            <>
                              <Typography className="invalid">{formik.errors.description}</Typography>
                            </>
                          ) : (
                            ""
                          )}
                        </Stack>
                      </Stack>
                    </div>
                    <Stack >
                      <FileUpload
                        className="d-flex"
                        labelName="Upload a file attachment (you can attach at most 3 files)"
                        inputFileName="validId"

                        files={files.validId}
                        onFileChange={handleFileChange}
                        onFileRemove={handleRemoveFile}
                      />
                    </Stack>
                  </div>
                  <Stack
                    className="my-3"
                    direction={"row"} justifyContent={"center"} alignItems={"center"} gap={"2rem"}>
                    <Button
                      variant="contained"
                      className={"d-flex align-items-center"}
                      style={{ gap: "0.525rem" }}
                      type="submit"
                      color={"primary"}
                      disabled={createTicketIsPending || !formik.isValid || !formik.values.description}
                    >
                      <span>Submit</span>
                      {createTicketIsPending ? <Spinner size={"sm"} color="white" /> : ""}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
                      Back
                    </Button>
                  </Stack>
                </div>
              </Form>
            </div>
          </Paper>
        </Container>
      )}
    </>
  );
}
