export const tabItems = [
  {
    id: 1,
    title: "Active",
    value: "active",
    status: "0",
    type: "status",
  },
  {
    id: 2,
    title: "Closed",
    value: "closed",
    status: "1",
    type: "status",
  },
  {
    id: 3,
    title: "Stared",
    value: "stared",
    status: "0",
    type: "is_stared",
  },
  {
    id: 4,
    title: "All",
    value: "all",
    status: "0",
    type: "all",
  },
];
