import { Box, Skeleton, Stack } from "@mui/material";

export default function AssignUserSkeleton() {
  const arrList = Array.from({ length: 4 });

  return (
    <Stack gap={1} className="px-2">
      {arrList.map((_, index) => (
        <Box key={index}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Box>
              <Skeleton variant="circular" animation="wave" sx={{ width: "2.25rem", height: "2.25rem" }} />
            </Box>
            <Skeleton animation="wave" sx={{ width: "100%", height: "3.25rem" }} />
          </Stack>
        </Box>
      ))}
    </Stack>
  );
}
