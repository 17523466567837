import React from "react";
export const TicketList = [
  {
    id: 1,
    name: "test",
    theme: "primary",
    date: "12 Jan",
    attactchment: true,
    marked: false,
    closed: false,
    messageTitle: "",
    message: "Hello team, I am facing problem as i can not select",
    messageMarkup: [
      "Hello team,",
      "I am facing problem as i can not select currency on buy order page. Can you guys let me know what i am doing doing wrong? Please check attached files.",
      "Thank you,",
      "Ishityak",
    ],
    reply: [
      {
        replyId: "rep_1",
        name: "Support Team",
        theme: "pink",
        date: "14 Jan, 2024",
        replyMarkup: [" "],
      },
      {
        meta: {
          metaId: "meta_1",
          metaMarkup: ``,
        },
      },
    ],
  },
];

const TicketContext = React.createContext(TicketList);
export default TicketContext;
