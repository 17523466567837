import { ConfirmationNumberOutlined as ConfirmationNumberIcon, HomeOutlined, LibraryAddOutlined } from "@mui/icons-material";

const menu = [
  {
    heading: "Ticket",
  },
  {
    icon: "home-alt",
    text: "Home",
    link: "/",
    type: "icon-comp",
    iconComp: HomeOutlined,
    dashboard: true,
  },
  {
    icon: "ticket",
    text: "Show Tickets",
    type: "icon-comp",
    iconComp: ConfirmationNumberIcon,
    link: "/ticket/tickets",
    dashboard: true,
  },
  {
    icon: "ticket-plus",
    text: "Add Ticket",
    link: "/create-ticket",
    type: "icon-comp",
    iconComp: LibraryAddOutlined,
    dashboard: true,
  },
];
export default menu;
